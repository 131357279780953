import { Injectable } from '@angular/core';
import { ReauthenticateRequest } from '@nowffc-shared/interfaces/reauthenticate-request';
import { environment } from '@nowffc-environment/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DeleteAccountService {
  constructor(private readonly http: HttpClient) {}

  createDeletionRequest(
    reauthenticateRequest: ReauthenticateRequest,
    reasonType: string,
    reasonText: string,
  ): Observable<{ success: boolean }> {
    return this.http
      .post(`${environment.bffUrl}/me/deletionRequest`, {
        login: reauthenticateRequest,
        reasonType,
        reasonText,
      })
      .pipe(
        map(() => {
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) =>
          throwError(() => err?.error?.title || 'generic.error.name'),
        ),
      );
  }

  revokeDeletionRequest(
    reauthenticateRequest: ReauthenticateRequest,
  ): Observable<{ success: boolean }> {
    return this.http
      .request('delete', `${environment.bffUrl}/me/deletionRequest`, {
        body: { login: reauthenticateRequest },
      })
      .pipe(
        map(() => {
          return { success: true };
        }),
        catchError((err: HttpErrorResponse) =>
          throwError(() => err?.error?.title || 'generic.error.name'),
        ),
      );
  }
}

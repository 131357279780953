import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@nowffc-auth/auth.service';
import { environment } from '@nowffc-environment/environment';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { PartnerService } from '@nowffc-shared/services/partner/partner.service';
import { UserService } from '@nowffc-shared/services/user/user.service';
import { WindowRef } from '@nowffc-shared/services/window/window';
import * as fromStore from '@nowffc-state/store';
import { Observable } from 'rxjs';
import { filter, mergeMap, switchMap, take, timeout } from 'rxjs/operators';

/**
 * class HttpTokenInterceptor
 */
@Injectable({ providedIn: 'root' })
export class HttpTokenInterceptor implements HttpInterceptor {
  private readonly forwardedHeaders = ['Accept', 'Content-Type'];

  /**
   * Wire DI
   */
  constructor(
    private readonly userService: UserService,
    private readonly authService: AuthService,
    private readonly windowRef: WindowRef,
    private readonly partnerService: PartnerService,
    private readonly errorsService: ErrorsService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  /**
   * check if the request url is against oidc
   */
  private static isOidcCall(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.oidc.stsServer);
  }

  private static isOidcApiCall(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.oidc.stsServer + '/api/');
  }

  /**
   * check if the request url is bff api
   */
  private static isBffCall(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.bffUrl);
  }

  /**
   * check if the request url is auth service refresh
   */
  private static isRefreshCall(request: HttpRequest<any>): boolean {
    return request.url.startsWith(`${environment.authUrl}/refresh`);
  }

  private static isRefreshOrOidcApiCall(request: HttpRequest<any>): boolean {
    return (
      HttpTokenInterceptor.isRefreshCall(request) ||
      HttpTokenInterceptor.isOidcApiCall(request)
    );
  }

  /**
   * Handle HttpRequests
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const appVersion = this.windowRef.nativeWindow.ffcAppVersion;

    if (
      HttpTokenInterceptor.isOidcCall(request) &&
      !HttpTokenInterceptor.isOidcApiCall(request)
    ) {
      return next.handle(request).pipe(timeout(environment.oidc.configTimeout));
    }

    if (request.url.startsWith(`${environment.authUrl}/login/byBearerToken`)) {
      return next.handle(request);
    }

    let authHeaders: HttpHeaders | null;
    return this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        take(1),
        mergeMap(() =>
          this.authService.getAccessToken().pipe(
            switchMap((accessToken) => {
              let httpHeaders = request.headers;
              if (accessToken) {
                // not null or empty string
                authHeaders = new HttpHeaders({
                  Authorization: `Bearer ${accessToken}`,
                });
              }

              if (HttpTokenInterceptor.isBffCall(request)) {
                if (authHeaders) {
                  httpHeaders = authHeaders;
                  httpHeaders = httpHeaders.set(
                    'X-APP',
                    `FFC-UI (${appVersion})`,
                  );
                } else {
                  httpHeaders = new HttpHeaders({
                    'X-APP': `FFC-UI (${appVersion})`,
                  });
                }
              } else if (
                authHeaders &&
                HttpTokenInterceptor.isRefreshOrOidcApiCall(request)
              ) {
                httpHeaders = authHeaders;
              }

              // change token if in /partner flow
              if (!accessToken || this.partnerService.hasStarted()) {
                const oldToken = this.userService.getAccessToken();
                const isValid = this.partnerService.checkTokenIsValid(oldToken);
                if (oldToken && isValid) {
                  httpHeaders = httpHeaders.delete('Authorization');
                  httpHeaders = httpHeaders.set('X-AUTH-TOKEN', oldToken);
                }
              }

              this.forwardedHeaders.forEach((forwardedHeader) => {
                const headerValue = request.headers.get(forwardedHeader);
                if (headerValue) {
                  httpHeaders = httpHeaders.set(forwardedHeader, headerValue);
                }
              });

              return next.handle(
                request.clone({
                  headers: httpHeaders,
                }),
              );
            }),
          ),
        ),
      );
  }
}

import { Injectable, OnDestroy, Signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@nowffc-auth/auth.service';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { User } from '@nowffc-shared/interfaces/user';
import { LoyjoyService } from '@nowffc-shared/services/loyjoy.service';
import { ConsentService } from '@nowffc-shared/services/tracking/consent.service';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { PaymentBearerTypeKey } from '@nowffc-shared/types/payment-bearer-type-key';
import * as fromStore from '@nowffc-state/store';
import { combineLatest, Subject } from 'rxjs';
import { filter, shareReplay, takeUntil } from 'rxjs/operators';
import { SubscriberDataSignalsState } from '../../../signals/states/subscriber-data.signals-state';

@Injectable({ providedIn: 'root' })
export class AccountChatBotService implements OnDestroy {
  private readonly loyJoyExperienceId = 'f5410822-7822-4d1c-818d-cce4bdb5f873';
  private readonly loyJoyExperienceWithoutConsent =
    '82638e1f-c60d-4a6c-8b12-f6992d6f1470';

  private readonly onDestroySubject = new Subject<void>();

  subscriberData: Signal<SubscriberData>;
  private user: User | undefined;

  readonly maxMobileWidth = 768;

  chatBotVisibilityCriteria$ = combineLatest([
    this.authService.isAuthenticated(),
    this.consentService.getConsentedAll$().pipe(shareReplay(1)),
  ]);

  constructor(
    private readonly loyJoyService: LoyjoyService,
    private readonly store: Store,
    private readonly consentService: ConsentService,
    private readonly windowRef: WindowRef,
    private readonly subscriberDataSignalsState: SubscriberDataSignalsState,
    private readonly authService: AuthService,
  ) {}

  async init() {
    this.initUserStateSub();

    this.chatBotVisibilityCriteria$.subscribe(
      async ([isLoggedIn, consentedAll]) => {
        if (!isLoggedIn) {
          this.loyJoyService.remove();
          return;
        }

        this.subscriberData =
          await this.subscriberDataSignalsState.getSubscriberData();

        const activeLoyjoyExperienceId = consentedAll
          ? this.loyJoyExperienceId
          : this.loyJoyExperienceWithoutConsent;

        if (!this.loyJoyService.isInitialized()) {
          this.loyJoyService.loadLoyJoy(
            activeLoyjoyExperienceId,
            !this.isMobile(),
            true,
            () => {},
            this.chatBotVarCallbacks,
          );
        }
      },
    );
  }

  private initUserStateSub() {
    this.store
      .select(fromStore.auth.selectUserEntity)
      .pipe(
        takeUntil(this.onDestroySubject),
        filter((user) => !!user),
      )
      .subscribe((user) => {
        this.user = user;
      });
  }

  private readonly chatBotVarCallbacks = {
    firstName: () => {
      return this.user ? this.user.firstname : '';
    },
    lastName: () => {
      return this.user ? this.user.lastname : '';
    },
    email: () => {
      return this.user ? this.user.email : '';
    },
    customerId: () => {
      return this.user ? this.user.uid : '';
    },
    paymentBearerTypeKey: () => {
      return this.resolvePaymentBearerTypeKey();
    },
    productName: () => {
      return this.subscriberData().internalSubscription.currentPhase
        .productName;
    },
    hasFreeSubscription: () => {
      return !this.subscriberData().computed.inPay;
    },
  };

  ngOnDestroy(): void {
    this.onDestroySubject.next();
  }

  isMobile() {
    return this.windowRef.nativeWindow.innerWidth < this.maxMobileWidth;
  }

  private resolvePaymentBearerTypeKey(): PaymentBearerTypeKey | null {
    if (
      this.subscriberData().internalSubscription &&
      this.subscriberData().internalSubscription.status === 'PAY'
    ) {
      switch (this.subscriberData().internalSubscription.paymentBearerType) {
        case 'AMAZON_PAY':
          return 'payment.bearer.type.amazonpay';
        case 'BANK_ACCOUNT':
          return 'payment.bearer.type.debit';
        case 'CREDIT_CARD':
          return 'payment.bearer.type.creditcard';
        case 'PAYPAL':
          return 'payment.bearer.type.paypal';
      }
    } else if (
      this.subscriberData().dtagSubscription ||
      this.subscriberData().externalPartnerDetails.length > 0
    ) {
      return 'payment.bearer.type.partner';
    } else if (this.subscriberData().iapSubscriptions.length > 0) {
      switch (this.subscriberData().iapSubscriptions[0].type) {
        case 'APPLE':
          return 'payment.bearer.type.appleiap';
        case 'AMAZON':
          return 'payment.bearer.type.amazoniap';
      }
    }

    return null;
  }
}

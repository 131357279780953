import { ModuleWithProviders, NgModule } from '@angular/core';
import { NowOverlayComponent } from './component/now-overlay.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { RouterModule } from '@angular/router';
import {
  DefaultNowOverlayConfig,
  NOW_OVERLAY_CONFIG,
  NowOverlayConfig,
} from './now-overlay-config';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingSpinnerComponent } from '@nowffc-shared/components/loading-spinner/loading-spinner.component';

/**
 * Loading Overlay Modul
 */
@NgModule({
  declarations: [NowOverlayComponent],
  imports: [
    OverlayModule,
    RouterModule,
    TranslateModule,
    LoadingSpinnerComponent,
  ],
})
export class NowOverlayModule {
  static forRoot(
    /* istanbul ignore next */
    config: Partial<NowOverlayConfig> = {},
  ): ModuleWithProviders<NowOverlayModule> {
    return {
      ngModule: NowOverlayModule,
      providers: [
        {
          provide: NOW_OVERLAY_CONFIG,
          useValue: {
            default: DefaultNowOverlayConfig,
            config,
          },
        },
      ],
    };
  }
}

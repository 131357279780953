import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { AuthService } from '@nowffc-auth/auth.service';
import * as fromStore from '@nowffc-state/store';

@Injectable({
  providedIn: 'root',
})
export class IsAnonymousGuard {
  constructor(
    private readonly store: Store,
    private readonly redirectService: RedirectService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(next, state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  checkStore(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() => {
          return this.authService.isAuthenticated().pipe(
            map((loggedIn) => {
              if (loggedIn) {
                console.log('IsAnonymousGuard denied access', {
                  state,
                });

                if (this.redirectService.hasRedirectUrl()) {
                  this.redirectService.executeRedirect();
                  return false;
                }

                const redirectPath =
                  next.data.isLoggedInTargetPath ?? '/account';

                return this.router.createUrlTree([redirectPath], {
                  queryParams: next.queryParams,
                  queryParamsHandling: 'merge',
                });
              }

              return true;
            }),
            take(1),
          );
        }),
      );
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@nowffc-auth/auth.service';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import {
  OFFERING_ERROR_COUNTRY_OF_CALLER_FORBIDDEN,
  SUBSCRIPTION_ERROR_PRODUCT_ALREADY_PURCHASED,
} from '@nowffc-shared/components/error-page/app-error-config';
import { OfferingQualifier } from '@nowffc-shared/services/offering/offering-qualifier';
import { OfferingService } from '@nowffc-shared/services/offering/offering.service';
import { LocalStorageService } from '@nowffc-shared/services/storage/local-storage.service';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';
import { addDays } from 'date-fns';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export interface ProductSelectionToken {
  productId: string;
  discountId?: string;
  qualifier?: OfferingQualifier | null;
  wasTrialOffered: boolean;
}

export const STORAGE_KEY_PRESELECTED_PRODUCT = 'ffc-product';

@Injectable({
  providedIn: 'root',
})
export class ProductSelectionService {
  private readonly routes = {
    PRODUCT_SELECTION: '/buchen',
    INITIAL_PRODUCT_SELECTION: '/buchen/initial',
    PRODUCT_OFFER: '/buchen/angebot',
    WINBACK_OFFER: '/buchen/winback',
    WINBACK_SUCCESS: '/buchen/winback/danke',
    DISCOUNT_OFFER: '/buchen/rabatt',
    DISCOUNT_SUCCESS: '/buchen/rabatt/danke',
    PAY: '/buchen/bezahlen',
    ERROR: '/account',
  };

  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly offeringService: OfferingService,
    private readonly storageService: LocalStorageService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly redirectService: RedirectService,
    private readonly toastrService: ToastrService,
  ) {}

  getEntryRoute(): Observable<string> {
    const qualifiedRoute = this.getQualifiedRoute();
    if (qualifiedRoute) {
      return of(qualifiedRoute);
    }

    return this.getProductSelectionRoute();
  }

  preselectProduct(productSelection: ProductSelectionToken) {
    this.setPreselectedProduct(productSelection);

    this.isLoggedIn().subscribe((loggedIn) => {
      if (loggedIn) {
        this.navigateTo(this.routes.PRODUCT_SELECTION, true);
      } else {
        this.authService.goToRegistration();
      }
    });
  }

  preselectOffer(qualifier: OfferingQualifier) {
    this.offeringService.setQualifier(qualifier);

    const qualifiedRoute = this.getQualifiedRoute();

    if (qualifiedRoute) {
      this.navigateTo(qualifiedRoute, true);
    }
  }

  hasPreselectedProduct() {
    return this.getPreselectedProduct() != null;
  }

  selectPreselectedProduct() {
    const product = this.getPreselectedProduct();
    if (product) {
      this.selectProduct(product);
    }
  }

  selectProduct({
    productId,
    discountId,
    qualifier,
    wasTrialOffered,
  }: ProductSelectionToken) {
    const internalDiscount = discountId
      ? {
          id: discountId,
        }
      : undefined;

    const internalProduct = {
      id: productId,
      discount: internalDiscount,
      wasTrialOffered,
    };

    const offeringJwt =
      qualifier?.type === 'offeringJwt' ? qualifier.value : undefined;
    const promotionCode =
      qualifier?.type === 'promotionCode' ? qualifier.value : undefined;

    from(
      this.subscriptionService.previewSubscription({
        product: internalProduct,
        discountId,
        offeringJwt,
        promotionCode,
      }),
    ).subscribe((previewResult) => {
      if (previewResult.success === false) {
        this.abortWithError(`subscription.error.${previewResult.error}`);
        return;
      }

      this.subscriptionService.storeOrderContext(
        previewResult.value,
        internalProduct,
        offeringJwt,
        promotionCode,
      );
      this.navigateTo(this.routes.PAY);
    });
  }

  selectWinbackOffer(qualifier: OfferingQualifier): Observable<boolean> {
    return this.offeringService.redeemWinbackOffer(qualifier).pipe(
      map((previewResult) => {
        if (previewResult.success === false) {
          this.abortWithError(previewResult.error);
          return false;
        }

        this.offeringService.clearQualifier();
        this.navigateTo(this.routes.WINBACK_SUCCESS, true);
        return true;
      }),
    );
  }

  selectDiscountOffer(qualifier: OfferingQualifier): Observable<boolean> {
    return this.offeringService.redeemDiscountOffer(qualifier).pipe(
      map((previewResult) => {
        if (previewResult.success === false) {
          this.abortWithError(previewResult.error);
          return false;
        }

        this.offeringService.clearQualifier();
        this.navigateTo(this.routes.DISCOUNT_SUCCESS, true);
        return true;
      }),
    );
  }

  abortWithFallback(message: string) {
    this.toastrService.error(message);
    this.navigateTo(this.routes.PRODUCT_SELECTION, true);
  }

  abortWithError(message: string) {
    if (message === SUBSCRIPTION_ERROR_PRODUCT_ALREADY_PURCHASED) {
      this.clearPreselectedProduct();
      this.navigateTo(
        'buchen/error/' + SUBSCRIPTION_ERROR_PRODUCT_ALREADY_PURCHASED,
        true,
      );
      return;
    }
    if (message === OFFERING_ERROR_COUNTRY_OF_CALLER_FORBIDDEN) {
      this.navigateTo(
        'buchen/error/' + OFFERING_ERROR_COUNTRY_OF_CALLER_FORBIDDEN,
        true,
      );
      return;
    }
    this.toastrService.error(message);
    this.navigateTo(this.routes.ERROR, true);
  }

  clearProduct() {
    this.offeringService.clearQualifier();
    this.clearPreselectedProduct();
  }

  clearRedirect() {
    const redirectUrl = this.redirectService.getRedirectUrl();

    if (redirectUrl?.startsWith('/')) {
      this.redirectService.deleteRedirectUrl();
    }
  }

  hasProductOfferQualifier() {
    return this.offeringService.hasProductOfferQualifier();
  }

  private setPreselectedProduct(productSelection: ProductSelectionToken) {
    this.storageService.set(
      STORAGE_KEY_PRESELECTED_PRODUCT,
      productSelection,
      addDays(new Date(), 1),
    );
  }

  private getPreselectedProduct(): ProductSelectionToken | null {
    return this.storageService.get<ProductSelectionToken>(
      STORAGE_KEY_PRESELECTED_PRODUCT,
    );
  }

  clearPreselectedProduct() {
    this.storageService.delete(STORAGE_KEY_PRESELECTED_PRODUCT);
  }

  private getQualifiedRoute() {
    if (this.offeringService.hasProductOfferQualifier()) {
      return this.routes.PRODUCT_OFFER;
    }

    if (this.offeringService.hasWinbackOfferQualifier()) {
      return this.routes.WINBACK_OFFER;
    }

    if (this.offeringService.hasDiscountOfferQualifier()) {
      return this.routes.DISCOUNT_OFFER;
    }

    return null;
  }

  private navigateTo(route: string, replaceUrl = false) {
    this.router.navigate([route], { replaceUrl });
  }

  private isLoggedIn(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(take(1));
  }

  private getProductSelectionRoute(): Observable<string> {
    return this.isLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.offeringService
            .hasUserOfferings()
            .pipe(
              map((hasUserOfferings) =>
                hasUserOfferings
                  ? this.routes.PRODUCT_SELECTION
                  : this.routes.ERROR,
              ),
            );
        }
        return of(this.routes.INITIAL_PRODUCT_SELECTION);
      }),
    );
  }
}

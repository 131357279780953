import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@nowffc-auth/auth.service';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { BrazeService } from '@nowffc-shared/services/braze/braze.service';
import { FeatureToggleService } from '@nowffc-shared/services/feature-toggle/feature-toggle.service';
import { PartnerService } from '@nowffc-shared/services/partner/partner.service';
import { TrackingService } from '@nowffc-shared/services/tracking/tracking.service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { DeleteAccountService } from '../../../modules/settings/services/delete-account.service';
import { fromAuthentication } from '../actions/authentication.action';
import { ErrorsService } from '@nowffc-errors/services/errors.service';

@Injectable()
export class AuthenticationEffects {
  authenticate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthentication.authenticate),
      exhaustMap(() => {
        return this.authService.recoverSession().pipe(
          map(({ uid, accountPersonalizationId, deletionDate }) => {
            if (accountPersonalizationId) {
              this.brazeService.login(accountPersonalizationId);
            }
            return fromAuthentication.authenticateSuccess({
              uid,
              accountPersonalizationId,
              deletionDate,
            });
          }),
          catchError((error) => {
            if (error.message) {
              this.toastrService.error(error.message);
            }

            return of(fromAuthentication.authenticateFail());
          }),
        );
      }),
    );
  });

  authenticateFail$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAuthentication.authenticateFail),
        map(() => {
          return this.router.navigate(['/logout']);
        }),
      );
    },
    { dispatch: false },
  );

  setNowJwtUsingBearerToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthentication.setNowJwtUsingBearerToken),
      switchMap(() =>
        this.authService.setNowJwtUsingBearerToken().pipe(
          map(({ nowJwt }) =>
            fromAuthentication.setNowJwtUsingBearerTokenSuccess({
              nowJwt,
            }),
          ),
          catchError((error) =>
            of(fromAuthentication.setNowJwtUsingBearerTokenFail({ error })),
          ),
        ),
      ),
    );
  });

  registerSuccessPartner$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAuthentication.registerPartnerSuccess),
        tap(() => {
          this.trackingService.trackAccountCreatedEvent();
        }),
      );
    },
    { dispatch: false },
  );

  revokeDeletionRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromAuthentication.revokeDeletionRequest),
      switchMap((reauthenticateRequest) => {
        return this.deleteAccountService
          .revokeDeletionRequest(reauthenticateRequest)
          .pipe(
            map(() => {
              this.router.navigate(['/account']);
              this.toastrService.success('settings.reactivate.account.success');

              return fromAuthentication.revokeDeletionRequestSuccess();
            }),
            catchError((errorMessage) => {
              this.toastrService.error(errorMessage);

              return of(fromAuthentication.revokeDeletionRequestFail());
            }),
          );
      }),
    );
  });

  logoutUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(fromAuthentication.logout),
        tap(() => {
          this.authService.logout();
          this.partnerService.logout();

          this.trackingService.trackAccountLoggedOutEvent();
          this.redirectService.deleteRedirectUrl();

          this.toggleService.clearCache();
          this.brazeService.logout();
        }),
        catchError((error) =>
          of(
            this.errorsService.logBugsnag('Logout Error', {
              addInfos: error,
              severity: 'warning',
            }),
          ),
        ),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly partnerService: PartnerService,
    private readonly toastrService: ToastrService,
    private readonly brazeService: BrazeService,
    private readonly redirectService: RedirectService,
    private readonly trackingService: TrackingService,
    private readonly deleteAccountService: DeleteAccountService,
    private readonly toggleService: FeatureToggleService,
    private readonly errorsService: ErrorsService,
  ) {}
}

import { createAction, props } from '@ngrx/store';

export const loadIsValidateEmailAddressExperimentEnabled = createAction(
  '[Feature Toggle] Load Is Validate Email Address Experiment Enabled',
  props<{ email: string }>(),
);
export const loadIsValidateEmailAddressExperimentEnabledFail = createAction(
  '[Feature Toggle] Load Is Validate Email Address Experiment Enabled Fail',
);
export const loadIsValidateEmailAddressExperimentEnabledSuccess = createAction(
  '[Feature Toggle] Load Is Validate Email Address Experiment Enabled Success',
  props<{ enabled: boolean }>(),
);

export const loadIsRetrievePasswordViaKeycloakExperimentEnabled = createAction(
  '[Feature Toggle] Load Is RetrievePasswordViaKeycloakExperiment Enabled',
);
export const loadIsRetrievePasswordViaKeycloakExperimentEnabledFail =
  createAction(
    '[Feature Toggle] Load Is RetrievePasswordViaKeycloakExperiment Fail',
  );
export const loadIsRetrievePasswordViaKeycloakExperimentEnabledSuccess =
  createAction(
    '[Feature Toggle] Load Is RetrievePasswordViaKeycloakExperiment Success',
    props<{ enabled: boolean }>(),
  );

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PaymentService } from '../modules/payment/services/payment.service';
import { AppConfigService } from '@nowffc-shared/services/app-config/app-config.service';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

@Injectable()
export class CanFinalizeGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly paymentService: PaymentService,
    private readonly errorsService: ErrorsService,
    private readonly appConfigService: AppConfigService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (
      !this.appConfigService.isModuleEnabled(
        'payment_provider_unique_user_identification',
      )
    ) {
      return of(true);
    }

    const returnUrlOrderUUID = route.queryParamMap.get('orderUUID');
    if (!returnUrlOrderUUID) {
      return of(true);
    }

    return this.checkStore(route, returnUrlOrderUUID).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(childRoute);
  }

  checkStore(
    route: ActivatedRouteSnapshot,
    returnUrlOrderUUID: string,
  ): Observable<boolean> {
    return this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        take(1),
        switchMap(() => {
          return this.store.select(fromStore.auth.selectUserId).pipe(
            map((userId) => {
              if (!userId) {
                return false;
              }

              const currentUserOrderUUID =
                this.paymentService.generateOrderUUID(userId);

              if (currentUserOrderUUID !== returnUrlOrderUUID) {
                this.handleGuardDenial(
                  `Intercepted invalid orderUUID=${currentUserOrderUUID} for userId=${userId}`,
                );
                return false;
              }

              return true;
            }),
          );
        }),
      );
  }

  /**
   * Redirects user to logout with an error message and logs a custom errorLogEntry
   * */
  private handleGuardDenial(errorLogEntry: string): void {
    this.errorsService.logBugsnag(errorLogEntry);
    this.errorsService.logToastr(
      'finalize.error.invalid.order.uuid.message',
      'finalize.error.invalid.order.uuid.headline',
      true,
    );

    this.router.navigate(['/logout']);
  }
}

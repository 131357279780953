import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, skip, throttleTime } from 'rxjs/operators';
import { AsyncPipe, NgIf } from '@angular/common';

interface ColorSet {
  r: string;
  t: string;
  l: string;
}

@Component({
  standalone: true,
  selector: 'now-plus-logo',
  templateUrl: './logo.component.svg',
  styleUrls: ['./logo.component.scss'],
  imports: [AsyncPipe, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit, OnDestroy {
  private readonly colorSets: ColorSet[] = [
    {
      r: '#FF5C01',
      t: '#D4000D',
      l: '#6E0CED',
    },
    {
      r: '#0C39ED',
      t: '#9629BC',
      l: '#ED0C93',
    },
    {
      r: '#EDC00C',
      t: '#EC33EC',
      l: '#FF5C01',
    },
    {
      r: '#008D49',
      t: '#0CAAED',
      l: '#009699',
    },
    {
      r: '#6E0CED',
      t: '#009699',
      l: '#00AF2C',
    },
    {
      r: '#9629BC',
      t: '#EC33EC',
      l: '#6E0CED',
    },
    {
      r: '#EDC00C',
      t: '#FF8A00',
      l: '#9629BC',
    },
    {
      r: '#0C75ED',
      t: '#D4000D',
      l: '#FF8A00',
    },
    {
      r: '#009699',
      t: '#0C39ED',
      l: '#EDC00C',
    },
    {
      r: '#0C39ED',
      t: '#0CAAED',
      l: '#9629BC',
    },
    {
      r: '#0C39ED',
      t: '#EDC00C',
      l: '#FF8A00',
    },
    {
      r: '#EC33EC',
      t: '#6E0CED',
      l: '#00AF2C',
    },
  ];

  private readonly _colorSet: BehaviorSubject<ColorSet> = new BehaviorSubject(
    this.getDistinctColorSet(),
  );

  private sub: Subscription;

  constructor(readonly router: Router) {}

  ngOnInit(): void {
    this.sub = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        skip(1),
        throttleTime(1000),
      )
      .subscribe(() => {
        this._colorSet.next(this.getDistinctColorSet(this._colorSet.value));
      });
  }

  /**
   * Returns a new color set which is different to the current one, as user assume an error when the logo sometimes
   * does not change the color, even though, that can happen on a random selection
   *
   * @param currentColorSet The current active color set
   * @returns New color set
   */
  getDistinctColorSet(currentColorSet?: ColorSet): ColorSet {
    if (currentColorSet == null) {
      return this.getColorSet();
    }

    let newColorSet;
    do {
      newColorSet = this.getColorSet();
    } while (this.isColorSetEqual(newColorSet, currentColorSet));

    return newColorSet;
  }

  getColorSet(): ColorSet {
    return this.colorSets[Math.floor(Math.random() * this.colorSets.length)];
  }

  isColorSetEqual(cs1: ColorSet, cs2: ColorSet): boolean {
    return cs1 && cs2 && cs1.r === cs2.r && cs1.t === cs2.t && cs1.r === cs2.r;
  }

  get colorSet$(): Observable<ColorSet> {
    return this._colorSet.asObservable();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@nowffc-environment/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ValidateEmailService {
  constructor(private readonly http: HttpClient) {}

  resendVerificationMail(): Observable<boolean> {
    return this.http
      .post(`${environment.bffUrl}/resendVerificationMail`, null)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
}

import { Injectable } from '@angular/core';
import {
  EventTypes,
  OidcSecurityService,
  PublicEventsService,
} from 'angular-auth-oidc-client';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import {
  catchError,
  filter,
  finalize,
  map,
  mergeMap,
  retry,
  take,
  tap,
} from 'rxjs/operators';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { UserService } from '@nowffc-shared/services/user/user.service';
import { environment } from '@nowffc-environment/environment';
import { Store } from '@ngrx/store';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { fromAuthentication } from '@nowffc-state/auth';
import { PartnerService } from '@nowffc-shared/services/partner/partner.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
    private readonly eventService: PublicEventsService,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly userService: UserService,
    private readonly redirectService: RedirectService,
    private readonly partnerService: PartnerService,
  ) {}

  isAuthenticated(): Observable<boolean> {
    return this.oidcSecurityService.isAuthenticated$.pipe(
      map(({ isAuthenticated }) => {
        if (isAuthenticated) {
          return true;
        }
        // check if old login is used
        return this.partnerService.isLoggedInWithOldLogin();
      }),
    );
  }

  getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken();
  }

  authorize(showLoginPrompt = true): Observable<boolean> {
    return this.oidcSecurityService.getRefreshToken().pipe(
      mergeMap((token) => {
        if (!token) {
          const authParams = showLoginPrompt
            ? {
                prompt: 'login',
              }
            : undefined;
          this.oidcSecurityService.authorize(undefined, {
            customParams: authParams,
          });

          return of(false);
        }

        return this.oidcSecurityService.forceRefreshSession().pipe(
          mergeMap((loginResponse) => {
            if (!loginResponse.isAuthenticated) {
              return of(false);
            }

            this.oidcSecurityService.authorize();

            return of(true);
          }),
        );
      }),
    );
  }

  recoverSession(): Observable<{
    loggedIn: boolean;
    uid?: number;
    accountPersonalizationId?: string;
    deletionDate?: string;
  }> {
    const checkAuthUrl = this.getCheckAuthUrl();

    // This is a Workaround because of a broken popup handling in the "angular-auth-oidc-client" library which is checked via the window.opener property:
    // Since Version 15.0.0 the library tries to redirect the auth-flow to the "main" window of an application if it was triggered from within a popup.
    // This seems to be done to access the correct context from the localStorage.
    // However this behaviour is also triggered if we enter the app from an external link
    // (like from this page: https://community.plus.rtl.de/s/kuendigung?contactReason=K%C3%BCndigung or from an eMail) and thus prevents the FFC from accessing the auth-context via the following "checkAuth" Method.
    window.opener = null;

    return this.oidcSecurityService.checkAuth(checkAuthUrl).pipe(
      mergeMap((auth) => {
        if (!auth?.isAuthenticated) {
          this.userService.clearAccessToken();
          return of({ loggedIn: false });
        }

        this.listenForEvents();

        return this.setNowJwtUsingBearerToken().pipe(
          map(({ nowJwt }) => {
            const { uid, accountPersonalizationId, deletionDate } =
              this.userService.getUserToken(nowJwt);

            return {
              loggedIn: true,
              uid,
              accountPersonalizationId,
              deletionDate,
            };
          }),
        );
      }),
    );
  }

  /**
   * only check url with query params when we just came back from login
   *
   * other routes might have query params (e.g. &token=) that won't
   * be handled properly by the oidc lib and result in a logout
   */
  private getCheckAuthUrl(): string {
    const { href, pathname } = window.location;

    return pathname === environment.oidc.postLoginRoute
      ? href
      : href.split('?')[0];
  }

  goToLogin(noPrompt = false): void {
    const customParams = noPrompt
      ? undefined
      : {
          prompt: 'login',
        };

    this.oidcSecurityService.authorize(undefined, {
      customParams,
    });
  }

  goToRegistration() {
    this.oidcSecurityService.authorize(undefined, {
      urlHandler: (url) => {
        this.redirectService.go(url.replace('/auth?', '/registrations?'));
      },
    });
  }

  forceRefreshSession(): Observable<boolean> {
    return this.oidcSecurityService.forceRefreshSession().pipe(map(() => true));
  }

  logout(): void {
    // first revoke the refresh token to terminate the client session in keycloak
    // the sso session will only be terminated if it was the only client session
    this.oidcSecurityService
      .revokeRefreshToken()
      .pipe(
        finalize(() => {
          this.userService.clearAccessToken();
          this.oidcSecurityService.logoffLocal();
        }),
      )
      .subscribe();
  }

  /**
   * logs user out and redirects to logout page
   */
  terminateRemoteSession(): void {
    this.oidcSecurityService
      .getIdToken()
      .pipe(
        mergeMap((idToken) => {
          let customParams = {};

          if (!idToken) {
            return EMPTY;
          }

          customParams = {
            ...customParams,
            id_token_hint: idToken,
          };

          return this.oidcSecurityService.logoff(undefined, {
            customParams,
          });
        }),
        take(1),
      )
      .subscribe();
  }

  private listenForEvents() {
    this.eventService
      .registerForEvents()
      .pipe(
        filter(
          (notification) =>
            notification.type === EventTypes.NewAuthenticationResult,
        ),
      )
      .subscribe(() => {
        this.store.dispatch(fromAuthentication.setNowJwtUsingBearerToken());
      });
  }

  setNowJwtUsingBearerToken(): Observable<{ nowJwt: string }> {
    return this.oidcSecurityService.getAccessToken().pipe(
      mergeMap((accessToken) =>
        this.http
          .post<{ token: string }>(
            `${environment.authUrl}/login/byBearerToken`,
            {
              accessToken,
            },
          )
          .pipe(
            retry({ count: 2, delay: 500 }),
            tap(({ token }) => this.userService.storeAccessToken(token)),
            map(({ token }) => ({ nowJwt: token })),
            catchError((err: HttpErrorResponse) =>
              throwError(() => ({
                message:
                  err.error?.title ?? 'userServiceError.login.byBearerToken',
              })),
            ),
          ),
      ),
    );
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { Observable, of } from 'rxjs';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';
import { AuthService } from '@nowffc-auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly redirectService: RedirectService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(next, state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(childRoute, state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  checkStore(
    activatedRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store
      .select(fromStore.auth.selectAuthenticationInitialized)
      .pipe(
        filter((initialized) => initialized),
        switchMap(() => {
          return this.authService.isAuthenticated().pipe(
            mergeMap((loggedIn) => {
              if (!loggedIn) {
                console.log('IsLoggedInGuard denied access', {
                  state,
                });

                this.redirectService.setRedirectUrl(state.url);

                const showLoginPrompt =
                  !activatedRoute.queryParamMap.get('noprompt');

                return this.authService.authorize(showLoginPrompt);
              }

              return of(true);
            }),
          );
        }),
      );
  }
}

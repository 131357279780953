import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';

/**
 * JwtService
 */
@Injectable({
  providedIn: 'root',
})
export class JwtService {
  decodeToken(token: string): any {
    return jwtDecode(token);
  }
}

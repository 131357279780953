import { ErrorHandler, NgModule } from '@angular/core';
import { ErrorsService } from './services/errors.service';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { ErrorsHandler } from './errors-handler';

/**
 * class errorsModule
 */
@NgModule({
  providers: [
    ErrorsService,
    WindowRef,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler,
    },
  ],
})
export class ErrorsModule {}

export function isGoogleTagManagerLoadingFailed(error: Error): boolean {
  return (
    new RegExp('Loading.*googletagmanager.*failed').test(
      error.message as string,
    ) ||
    new RegExp('Timeout while loading.*googletagmanager').test(
      error.message as string,
    )
  );
}

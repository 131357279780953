import { effects, fromAuthentication, reducers } from './';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@nowffc-environment/environment';
import {
  AbstractLoggerService,
  AbstractSecurityStorage,
  AuthModule as OidcAuthModule,
} from 'angular-auth-oidc-client';
import { ErrorsService } from '@nowffc-errors/services/errors.service';

@Injectable()
export class MyStorageService implements AbstractSecurityStorage {
  read(key: string) {
    return localStorage.getItem(key);
  }

  write(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}

@Injectable()
export class MyLoggerService implements AbstractLoggerService {
  constructor(
    private readonly errorsService: ErrorsService,
    private readonly store: Store,
  ) {}

  logDebug(message: any, ...args: any[]): void {
    if (args.length > 0) {
      console.debug(message, args);
    } else {
      console.debug(message);
    }
  }

  logError(message: any, ...args: any[]): void {
    this.errorsService.logBugsnag(message, {
      addInfos: args.length > 0 ? args : undefined,
      severity: 'error',
    });

    // workaround to avoid infinite /token retries when CodeVerifier is missing in oidcSecurityService.checkAuth(...)
    if (message?.toString()?.includes('CodeVerifier is not set')) {
      this.store.dispatch(fromAuthentication.logout());
    }
  }

  logWarning(message: any, ...args: any[]): void {
    this.errorsService.logBugsnag(message, {
      addInfos: args.length > 0 ? args : undefined,
      severity: 'warning',
    });
  }
}

@NgModule({
  imports: [
    OidcAuthModule.forRoot({
      config: {
        // disable validations to allow users
        // with drifted time settings to log in
        // tokens have to be validated by the backend systems
        disableIdTokenValidation: true,
        disableIatOffsetValidation: true,

        authority: environment.oidc.stsServer,
        redirectUrl: `${window.location.origin}${environment.oidc.postLoginRoute}`,
        postLoginRoute: environment.oidc.postLoginRoute,
        unauthorizedRoute: '/logout',
        forbiddenRoute: '/logout',
        postLogoutRedirectUri: `${window.location.origin}/logout`,
        clientId: environment.oidc.clientId,
        scope: 'openid profile email tvn',
        responseType: 'code',
        silentRenew: true,
        startCheckSession: false,
        logLevel: environment.oidc.logLevel,
        autoUserInfo: false,
        useRefreshToken: true,
        ignoreNonceAfterRefresh: true,
      },
    }),
    StoreModule.forFeature('auth', reducers),
    EffectsModule.forFeature(effects),
  ],
  providers: [
    { provide: AbstractSecurityStorage, useClass: MyStorageService },
    { provide: AbstractLoggerService, useClass: MyLoggerService },
  ],
})
export class AuthStoreModule {}

import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LoginSuccess } from '@nowffc-shared/services/partner/partner.service';
import { ReauthenticateRequest } from '@nowffc-shared/interfaces/reauthenticate-request';

export const fromAuthentication = createActionGroup({
  source: 'Auth',
  events: {
    // Authenticate
    Authenticate: emptyProps(),
    'Authenticate Fail': emptyProps(),
    'Authenticate Success': props<{
      uid?: number;
      accountPersonalizationId?: string;
      deletionDate?: string;
    }>(),

    // Set nowJwt using Bearer Token
    'Set nowJwt using Bearer Token': emptyProps(),
    'Set nowJwt using Bearer Token Fail': props<{
      error: {
        message: string;
      };
    }>(),
    'Set nowJwt using Bearer Token Success': props<{ nowJwt: string }>(),

    //Login Partner
    'Login Partner Fail': props<{
      error: {
        message: string;
      };
    }>(),
    'Login Partner Success': props<LoginSuccess>(),

    // Register Partner
    'Register Partner Fail': props<{ error: { message: string } }>(),
    'Register Partner Success': props<LoginSuccess>(),

    // Logout
    Logout: emptyProps(),

    // Revoke Deletion Request
    'Revoke Deletion Request': props<ReauthenticateRequest>(),
    'Revoke Deletion Request Fail': emptyProps(),
    'Revoke Deletion Request Success': emptyProps(),
  },
});

import { User } from '@nowffc-shared/interfaces/user';
import { createReducer, on } from '@ngrx/store';
import * as fromUser from '../actions/user.action';
import { fromAuthentication } from '../actions';

export interface UserState {
  entity?: User;
  loaded: boolean;
  loading: boolean;
  error?: { message: string };
}

export const initialState: UserState = {
  entity: undefined,
  loaded: false,
  loading: false,
  error: undefined,
};

export const reducer = createReducer(
  initialState,

  // loadUser
  on(fromUser.loadUser, (state): UserState => ({ ...state, loading: true })),
  on(
    fromUser.loadUserSuccess,
    (state, { user }): UserState => ({
      ...state,
      loading: false,
      loaded: true,
      entity: { ...state.entity, ...user },
    }),
  ),
  on(
    fromUser.loadUserFail,
    (state, { error }): UserState => ({
      ...state,
      loading: false,
      loaded: true,
      error,
    }),
  ),

  // TODO: loginPartner - use loadUser instead in future requests
  on(
    fromAuthentication.loginPartnerSuccess,
    (
      state,
      { user, uid, accountPersonalizationId, deletionDate },
    ): UserState => ({
      ...state,
      loading: false,
      loaded: true,
      entity: {
        ...state.entity,
        ...user,
        uid,
        accountPersonalizationId,
        deletionDate,
      },
      error: undefined,
    }),
  ),
  on(
    fromAuthentication.loginPartnerFail,
    (state, { error }): UserState => ({
      ...state,
      loading: false,
      loaded: false,
      error,
    }),
  ),

  // updateUser
  on(fromUser.updateUser, (state): UserState => ({ ...state, loading: true })),
  on(
    fromUser.updateUserSuccess,
    (state, { user }): UserState => ({
      ...state,
      loading: false,
      entity: { ...state.entity, ...user },
      error: undefined,
    }),
  ),
  on(
    fromUser.updateUserFail,
    (state, { error }): UserState => ({
      ...state,
      loading: false,
      error,
    }),
  ),

  on(
    fromAuthentication.registerPartnerSuccess,
    (state, { user }): UserState => ({
      ...state,
      loading: false,
      loaded: true,
      entity: { ...state.entity, ...user },
      error: undefined,
    }),
  ),
  on(
    fromAuthentication.registerPartnerFail,
    (state, { error }): UserState => ({
      ...state,
      loading: false,
      loaded: false,
      error,
    }),
  ),

  // moved from authentication reducer
  on(
    fromAuthentication.authenticateSuccess,
    (state, { uid, accountPersonalizationId, deletionDate }): UserState => ({
      ...state,
      entity: {
        ...state.entity,
        uid,
        accountPersonalizationId,
        deletionDate,
      },
    }),
  ),

  // revokeDeletionRequest
  on(
    fromAuthentication.revokeDeletionRequestSuccess,
    (state): UserState => ({
      ...state,
      entity: {
        ...state.entity,
        deletionDate: undefined,
      },
    }),
  ),

  // logout
  on(fromAuthentication.logout, (): UserState => initialState),
);

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';
import { PartnerService } from '@nowffc-shared/services/partner/partner.service';
import { ProductSelectionService } from '@nowffc-shared/services/product-selection/product-selection.service';

@Injectable({
  providedIn: 'root',
})
export class ExtendProfileGuard {
  constructor(
    private readonly store: Store,
    private readonly partnerService: PartnerService,
    private readonly productSelectionService: ProductSelectionService,
    private readonly router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.auth.selectUserLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(fromStore.auth.loadUser());
        }
      }),
      filter((loaded) => loaded),
      take(1),
      switchMap(() => {
        return this.store
          .select(fromStore.auth.selectIsBasicProfileCompleted)
          .pipe(
            switchMap((completed) => {
              if (completed) {
                return this.productSelectionService
                  .getEntryRoute()
                  .pipe(
                    map((targetRoute) =>
                      this.router.createUrlTree([targetRoute]),
                    ),
                  );
              }

              if (state.url === '/partner/account-vervollstaendigen') {
                return of(true);
              }

              return this.partnerService.isPartnerAccount().pipe(
                take(1),
                map((isPartnerAccount) => {
                  return isPartnerAccount
                    ? this.router.createUrlTree([
                        '/partner/account-vervollstaendigen',
                      ])
                    : true;
                }),
              );
            }),
          );
      }),
    );
  }
}

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

@Injectable({
  providedIn: 'root',
})
export class HasUserGuard {
  constructor(private readonly store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  checkStore(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.auth.selectUserLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(fromStore.auth.loadUser());
        }
      }),
      filter((loaded) => loaded),
      take(1),
      switchMap(() =>
        this.store.select(fromStore.auth.selectUserEntity).pipe(
          map((user) => {
            if (!user) {
              console.log('HasUserGuard denied access', { state });

              return false;
            }

            return true;
          }),
        ),
      ),
    );
  }
}

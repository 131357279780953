import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ErrorsService } from './services/errors.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrException } from '@nowffc-shared/exceptions/toastrException';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NowOverlayService } from '@nowffc-overlay/service/now-overlay.service';
import { WindowRef } from '@nowffc-shared/services/window/window';

/**
 * class Errors Handler
 */
@Injectable()
export class ErrorsHandler implements ErrorHandler {
  /**
   * Wire DI
   */
  constructor(
    private readonly injector: Injector,
    private readonly windowRef: WindowRef,
  ) {}

  /**
   * handle the Error
   */
  handleError(error: any) {
    const translateService = this.injector.get(TranslateService);
    const overlayService = this.injector.get(NowOverlayService);

    // Close the Overlay to see Messages
    overlayService.closeOverlay();

    // Handelt es sich um eine Promise.rejection, dann wird die
    // HttpErrorResponse in error.rejection hinterlegt
    let internalError = error;
    if (!!error.rejection) {
      internalError = error.rejection;
    }

    if (internalError instanceof HttpErrorResponse) {
      // Server or connection error happened
      /* istanbul ignore if */
      if (!navigator.onLine) {
        // Handle offline error
        this.handleClientError(
          new ToastrException(
            translateService.instant('generic.error.connection.lost.message'),
            translateService.instant('generic.error.connection.lost.title'),
          ),
        );
      } else {
        // Handle Http Error (error.status === 403, 404...)
        this.handleHttpErrorResponse(internalError);
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      this.handleClientError(internalError);
    }
  }

  /**
   * Handle Http Error Responses
   */
  handleHttpErrorResponse(response: HttpErrorResponse) {
    const errorsService = this.injector.get(ErrorsService);
    const router = this.injector.get(Router);

    let titleTranslateKey = 'generic.error.name';
    let messageTranslateKey = 'generic.error.description';

    errorsService.logConsole(response);
    errorsService.logBugsnag(response);

    // todo 405 	Method Not Allowed+
    // todo 408 	Request Timeout
    // todo 500

    // Teapot
    if (response.status === 418) {
    }
    // Route to Error Page on Forbidden Actions
    if (response.status === 403) {
      // Wenn das Ticket https://jira.netrtl.com/browse/CUS-463 abgeschlossen ist
      // Können wir die Übersetzungen durchschleifen
      if (response.error.error === 'CustomerIsLockedException') {
        errorsService.setErrorDetails(
          'errors.customer.locked.title',
          'errors.customer.locked',
          403,
        );
      }

      router.navigate(['/fehler']);
      return;
    }

    if (typeof response.error.title === 'string') {
      titleTranslateKey = response.error.title;
    } else if (typeof response.error.error === 'string') {
      titleTranslateKey = response.error.error;
    } else if (
      typeof response.error.error === 'object' &&
      typeof response.error.error.message === 'string'
    ) {
      titleTranslateKey = `errors.v3.${response.error.error.message}`;
      messageTranslateKey = `errors.v3.${response.error.error.message}.message`;
    }
    errorsService.logToastr(messageTranslateKey, titleTranslateKey);
  }

  /**
   * Handle Client Errors
   */
  handleClientError(error: any) {
    const errorsService = this.injector.get(ErrorsService);

    errorsService.logConsole(error);
    /* istanbul ignore else */
    if (error.showInToastr) {
      errorsService.logToastr(error.userMessageKey, error.userHeadline);
    }

    if (
      /Loading chunk [\d]+ failed/.test(error.message) &&
      error.stack &&
      this.windowRef.nativeWindow.localStorage.getItem('lastChunkError') !==
        error.stack
    ) {
      // Save the last error to avoid an infinite reload loop if the chunk
      // really does not exists after reload
      this.windowRef.nativeWindow.localStorage.setItem(
        'lastChunkError',
        error.stack,
      );

      errorsService.logBugsnag(error, { severity: 'info' });

      this.windowRef.nativeWindow.location.reload();

      return;
    }

    // Report every Client Error and Toastr-Exceptions that want to report
    /* istanbul ignore else */
    if (error.reportToTracker === true || error.reportToTracker === undefined) {
      errorsService.logBugsnag(error);
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthorizationService } from '@nowffc-shared/services/authorization/authorization.service';
import { FeatureToggleService } from '@nowffc-shared/services/feature-toggle/feature-toggle.service';
import { CONTRACT_FEATURE_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';
import * as fromStore from '@nowffc-state/store';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/**
 * class NoMaintenanceGuard
 */
@Injectable({
  providedIn: 'root',
})
export class NoMaintenanceGuard {
  constructor(
    private readonly store: Store,
    private readonly toggleService: FeatureToggleService,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.auth.selectUserEntity).pipe(
      switchMap((user) => {
        return this.authorizationService.isFallback().pipe(
          switchMap((isFallback) => {
            if (isFallback) {
              return of(this.router.createUrlTree(['/wartung']));
            }
            const usersEmail = user?.email;

            return from(
              this.toggleService.isEnabledCached(
                CONTRACT_FEATURE_TOGGLES.SATURN_MAINTENANCE,
                usersEmail,
              ),
            ).pipe(
              map((enabled) => {
                return enabled ? this.router.createUrlTree(['/wartung']) : true;
              }),
              catchError(() => of(false)),
            );
          }),
        );
      }),
    );
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this.canActivate();
  }
}

import { Injectable } from '@angular/core';

/**
 * return the global native browser window object
 *
 * @return window Native Browser window object
 */
function _window(): any {
  // return the global native browser window object
  return window;
}

/**
 * class WindowRef
 */
@Injectable()
export class WindowRef {
  /**
   * get the global native browser window object
   */
  get nativeWindow(): any {
    return _window();
  }
}

export enum OfferingQualifierType {
  OFFERING_JWT = 'offeringJwt',
  WINBACK_JWT = 'winbackJwt',
  DISCOUNT_JWT = 'discountJwt',
  PROMOTION_CODE = 'promotionCode',
}

export class OfferingQualifier {
  /**
   * Qualifier types, ordered by priority
   */
  static readonly TYPES = [
    OfferingQualifierType.OFFERING_JWT,
    OfferingQualifierType.WINBACK_JWT,
    OfferingQualifierType.DISCOUNT_JWT,
    OfferingQualifierType.PROMOTION_CODE,
  ];

  static newOfferingJwt(value: string) {
    return new OfferingQualifier(OfferingQualifierType.OFFERING_JWT, value);
  }

  static newWinbackJwt(value: string) {
    return new OfferingQualifier(OfferingQualifierType.WINBACK_JWT, value);
  }

  static newDiscountJwt(value: string) {
    return new OfferingQualifier(OfferingQualifierType.DISCOUNT_JWT, value);
  }

  static newPromotionCode(value: string) {
    return new OfferingQualifier(OfferingQualifierType.PROMOTION_CODE, value);
  }

  constructor(
    readonly type: OfferingQualifierType,
    readonly value: string,
  ) {}
}

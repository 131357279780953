import {
  IsCustomerActiveGuard,
  IsLoggedInGuard,
  NoMaintenanceGuard,
} from '@nowffc-guards';
import { FfcRoutes } from '@nowffc-shared/types/ffc-routes';
import { Page } from '@plus/tracking-model-web';
import { featureToggleGuard } from './guards/feature-toggle.guard';

export const APP_ROUTES: FfcRoutes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.routes').then((c) => c.LOGIN_ROUTES),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/menu/menu.routes').then((c) => c.MENU_ROUTES),
  },
  {
    path: 'info',
    loadChildren: () =>
      import('./modules/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'buchen',
    loadChildren: () =>
      import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'presale/:token',
    canActivate: [IsLoggedInGuard],
    loadComponent: () =>
      import('./modules/presale/presale.component').then(
        (c) => c.PresaleComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypepresale,
    },
  },
  {
    path: 'partner-aktivierung',
    canActivate: [
      featureToggleGuard({
        allDisabled: ['account_services_ffc_KILL_partner_code_redemption'],
        redirectUrl: '404',
      }),
    ],
    loadChildren: () =>
      import(
        './modules/partner-code-redemption/partner-code-redemption.module'
      ).then((m) => m.PartnerCodeRedemptionModule),
  },
  {
    path: 'partner',
    loadChildren: () =>
      import('./modules/partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: 'persoenliche-daten',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'registrierung',
    loadChildren: () =>
      import('./modules/registration/registration.routes').then(
        (m) => m.REGISTRATION_ROUTES,
      ),
  },
  {
    path: 'einstellungen',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
  },
  {
    path: 'mein-paket',
    loadChildren: () =>
      import('./modules/accounting/accounting.module').then(
        (m) => m.AccountingModule,
      ),
  },
  {
    path: 'coop',
    loadChildren: () =>
      import('./modules/coop-coupon/coop-coupon.module').then(
        (m) => m.CoopCouponModule,
      ),
  },
  {
    path: 'datenauskunft',
    loadComponent: () =>
      import('./modules/gdpr/gdpr-base.component').then(
        (c) => c.GdprBaseComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypegdprdiscloure,
    },
  },
  {
    path: 'email-aendern/:token',
    loadComponent: () =>
      import('./modules/change-email/components/change-email.component').then(
        (c) => c.ChangeEmailComponent,
      ),
    canActivate: [NoMaintenanceGuard, IsCustomerActiveGuard],
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypechangeemail,
    },
  },
  {
    path: 'logout',
    loadComponent: () =>
      import('./modules/logout/logout.component').then(
        (c) => c.LogoutComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypelogout,
    },
  },
  {
    path: 'passwort-vergessen',
    canActivate: [NoMaintenanceGuard],
    loadComponent: () =>
      import(
        './modules/password/components/retrieve-password/retrieve-password.component'
      ).then((m) => m.RetrievePasswordComponent),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypepasswordresetinit,
    },
  },
  {
    path: 'passwort-festlegen/:token',
    loadComponent: () =>
      import(
        './modules/password/components/reset-password/reset-password.component'
      ).then((c) => c.ResetPasswordComponent),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypepasswordresetengage,
    },
  },
  {
    path: 'newsletter',
    loadChildren: () =>
      import('./modules/newsletter/newsletter.module').then(
        (m) => m.NewsletterModule,
      ),
  },
  {
    path: 'newsletter-abmeldung',
    loadComponent: () =>
      import(
        './modules/settings/views/communication/unsubscribe/unsubscribe-communication.component'
      ).then((c) => c.UnsubscribeCommunicationComponent),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypenewsletterunsubscribe,
    },
  },
  {
    path: 'wartung',
    loadComponent: () =>
      import('./modules/errors/views/maintenance/maintenance.component').then(
        (c) => c.MaintenanceComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypemaintenance,
    },
  },
  {
    path: 'sperre',
    loadComponent: () =>
      import('./modules/errors/views/locked/locked.component').then(
        (c) => c.LockedComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypepaymentlock,
    },
  },
  {
    path: 'konto-hinweis',
    loadComponent: () =>
      import('./modules/errors/views/written-off/written-off.component').then(
        (c) => c.WrittenOffComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypewrittenoff,
    },
  },
  {
    path: '.well-known/change-password',
    redirectTo: 'persoenliche-daten/passwort-aendern',
  },
  {
    path: 'buchung',
    redirectTo: '/persoenliche-daten',
    pathMatch: 'full',
  },
  {
    path: 'profil',
    redirectTo: '/account',
    pathMatch: 'full',
  },
  {
    path: 'profil/debug',
    redirectTo: '/persoenliche-daten/debug',
    pathMatch: 'full',
  },
  {
    path: 'profil/pin-verwalten',
    redirectTo: '/persoenliche-daten/pin-verwalten',
    pathMatch: 'full',
  },
  {
    path: 'account/abo-kuendigen',
    redirectTo: '/mein-paket/abo-kuendigen',
    pathMatch: 'full',
  },
  {
    path: 'account/abo-kuendigen-bestaetigen',
    redirectTo: '/mein-paket/abo-kuendigen-bestaetigen',
    pathMatch: 'full',
  },
  {
    path: 'account/kuendigung-widerrufen',
    redirectTo: '/mein-paket/kuendigung-widerrufen',
    pathMatch: 'full',
  },
  {
    path: 'account/zahlungsdaten-aendern',
    redirectTo: '/mein-paket/zahlungsdaten-aendern',
    pathMatch: 'full',
  },
  {
    path: 'buchen/profil-aktualisieren',
    redirectTo: '/registrierung/account-vervollstaendigen',
    pathMatch: 'full',
  },
  {
    path: 'registrierung/profil',
    redirectTo: '/registrierung/account-vervollstaendigen',
    pathMatch: 'full',
  },
  {
    path: 'einstellungen/konto-loeschen',
    redirectTo: '/einstellungen/account-loeschen',
    pathMatch: 'full',
  },
  {
    path: 'einstellungen/konto-loeschen-bestaetigen',
    redirectTo: '/einstellungen/account-loeschen-bestaetigen',
    pathMatch: 'full',
  },
  {
    path: 'einstellungen/konto-reaktivieren',
    redirectTo: '/einstellungen/account-reaktivieren',
    pathMatch: 'full',
  },
  {
    path: 'fehler',
    loadComponent: () =>
      import('./modules/errors/views/errors/errors.component').then(
        (c) => c.ErrorsComponent,
      ),
    data: {
      rri: Page.TypeEnum.Customerdiscoverypagetypeerror,
    },
  },
  {
    path: '**',
    loadComponent: () =>
      import('./modules/errors/views/errors/errors.component').then(
        (c) => c.ErrorsComponent,
      ),
    data: {
      error: 404,
      rri: Page.TypeEnum.Customerdiscoverypagetypeerror,
    },
  },
];

/* istanbul ignore file */

import { NowOverlayInterface } from './interface/now-overlay.interface';

/**
 * Overlay Data to inject in component
 */
export class NowOverlayData implements NowOverlayInterface {
  /**
   * Headline for Overlay
   */
  headline = '';
  /**
   * Text Message for Overlay
   */
  text = '';
}

import { Signal, signal } from '@angular/core';

/**
 * - This service stores state with signals.
 *
 * - To use the signal state for your objects
 *    1. implement a class that extends this class.
 *       For example see subscriber-data.signals-state.ts
 *    2. Inject this class to your services.
 *    3. Use the set(object) method to assign a value to the state.
 *    4. Use the setState(key, data) to update a property of the object in the state
 *    5. To read the state, use state.asReadonly()
 */
export abstract class SignalsStateService<T> {
  private state = signal({} as T);
  private initialized = false;

  protected abstract init(): void;

  /**
   * Sets values for multiple properties on the store.
   * This is used to update the whole object in the state.
   */
  public setState(partialState: Partial<T>): void {
    this.state.update((currentValue) => ({ ...currentValue, ...partialState }));
    this.initialized = true;
  }

  public getState(): Signal<T> {
    if (this.initialized) {
      return this.state.asReadonly();
    } else {
      throw new Error('signals.state.not.initialized.error');
    }
  }

  public isInitialized() {
    return this.initialized;
  }

  public clear() {
    this.setState({});
    this.initialized = false;
  }
}

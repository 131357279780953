import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromFeatureToggle from './feature.reducer';

export interface ExperimentsState {
  features: fromFeatureToggle.FeatureState;
}

export const reducers: ActionReducerMap<ExperimentsState> = {
  features: fromFeatureToggle.reducer,
};

export const selectFeatureToggleState =
  createFeatureSelector<ExperimentsState>('experiments');

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@nowffc-environment/environment';
import { addDays } from 'date-fns';
import { ProductOfferingError } from '@nowffc-shared/types/product-offering-error';
import { Observable, of } from 'rxjs';
import { LocalStorageService } from '@nowffc-shared/services/storage/local-storage.service';
import { CheckPromotionCodeError } from '@nowffc-shared/types/check-promotion-code-error';
import { catchError, map } from 'rxjs/operators';
import {
  MaybeValue,
  MaybeValueError,
  MaybeValueSuccess,
} from '@nowffc-shared/types/maybe-value';
import {
  OfferingQualifier,
  OfferingQualifierType,
} from '@nowffc-shared/services/offering/offering-qualifier';
import { OfferingResponseV4 } from '@nowffc-shared/interfaces/offering/v4/offering-response.v4';
import { RedeemWinbackJwtError } from '@nowffc-shared/types/redeem-winback-jwt-error';
import { RedeemDiscountJwtError } from '@nowffc-shared/types/redeem-discount-jwt-error';
import { OfferingProductV4 } from '@nowffc-shared/interfaces/offering/v4/offering-product.v4';

@Injectable({
  providedIn: 'root',
})
export class OfferingService {
  constructor(
    private readonly http: HttpClient,
    private readonly storageService: LocalStorageService,
  ) {}

  private readonly storageKeys: Record<OfferingQualifierType, string> = {
    [OfferingQualifierType.OFFERING_JWT]: 'ffc-offeringjwt',
    [OfferingQualifierType.WINBACK_JWT]: 'ffc-winbackjwt',
    [OfferingQualifierType.DISCOUNT_JWT]: 'ffc-discountjwt',
    [OfferingQualifierType.PROMOTION_CODE]: 'ffc-promocode',
  };

  hasProductOfferQualifier() {
    const qualifierType = this.getQualifier()?.type;
    return (
      qualifierType === OfferingQualifierType.OFFERING_JWT ||
      qualifierType === OfferingQualifierType.PROMOTION_CODE
    );
  }

  hasWinbackOfferQualifier() {
    return this.getQualifier()?.type === OfferingQualifierType.WINBACK_JWT;
  }

  hasDiscountOfferQualifier() {
    return this.getQualifier()?.type === OfferingQualifierType.DISCOUNT_JWT;
  }

  getQualifier(): OfferingQualifier | null {
    for (const qualifierType of OfferingQualifier.TYPES) {
      const storedItem = this.getStoredItem(qualifierType);
      if (storedItem) {
        return new OfferingQualifier(qualifierType, storedItem);
      }
    }

    return null;
  }

  setQualifier(qualifier: OfferingQualifier): void {
    this.storeItem(qualifier.type, qualifier.value);
  }

  clearQualifier() {
    OfferingQualifier.TYPES.forEach((qualifierType) => {
      this.storageService.delete(this.storageKeys[qualifierType]);
    });
  }

  checkPromotionCode(
    promotionCode: string,
  ): Observable<MaybeValue<any, CheckPromotionCodeError>> {
    return this.http
      .post(`${environment.bffUrl}/promotion/preview`, {
        promotionCode,
      })
      .pipe(
        map(OfferingService.mapValue),
        catchError(OfferingService.mapError),
      );
  }

  redeemWinbackOffer(
    qualifier: OfferingQualifier,
  ): Observable<MaybeValue<any, RedeemWinbackJwtError>> {
    if (qualifier.type !== OfferingQualifierType.WINBACK_JWT) {
      throw new Error('Invalid qualifier: winbackJwt required');
    }

    return this.http
      .post(`${environment.bffUrl}/productOfferings/winback`, {
        winbackJwt: qualifier.value,
      })
      .pipe(
        map(OfferingService.mapValue),
        catchError(OfferingService.mapError),
      );
  }

  redeemDiscountOffer(
    qualifier: OfferingQualifier,
  ): Observable<MaybeValue<any, RedeemDiscountJwtError>> {
    if (qualifier.type !== OfferingQualifierType.DISCOUNT_JWT) {
      throw new Error('Invalid qualifier: discountJwt required');
    }

    return this.http
      .post(`${environment.bffUrl}/productOfferings/discount`, {
        discountJwt: qualifier.value,
      })
      .pipe(
        map(OfferingService.mapValue),
        catchError(OfferingService.mapError),
      );
  }

  hasUserOfferings(): Observable<boolean> {
    return this.getUserOfferingsV4().pipe(
      map((result) => result.success && result.value.productGroups.length > 0),
    );
  }

  getInitialOfferingsV4() {
    return this.getProductOfferingV4(
      `${environment.bffUrl}/initialProductOfferings`,
    );
  }

  getUserOfferingsV4() {
    return this.getProductOfferingV4(`${environment.bffUrl}/productOfferings`);
  }

  getQualifiedOfferingV4(qualifier: OfferingQualifier) {
    return this.getProductOfferingV4(
      `${environment.bffUrl}/productOfferings?${qualifier.type}=${qualifier.value}`,
    );
  }

  getInitialPromotionCodeOfferingV4(promotionCode: string) {
    return this.getProductOfferingV4(
      `${environment.bffUrl}/initialProductOfferings?${OfferingQualifierType.PROMOTION_CODE}=${promotionCode}`,
    );
  }

  private getProductOfferingV4(
    url: string,
  ): Observable<MaybeValue<OfferingResponseV4, ProductOfferingError>> {
    return this.http
      .get<OfferingResponseV4>(url, {
        headers: {
          Accept: 'application/vnd.tvnow.ffcbff.product-offering.v4+json',
        },
      })
      .pipe(
        map(OfferingService.mapValue),
        map(OfferingService.mapOffers),
        catchError(OfferingService.mapError),
      );
  }

  private storeItem(name: OfferingQualifierType, value: string) {
    this.storageService.set(
      this.storageKeys[name],
      value,
      addDays(new Date(), 1),
    );
  }

  private getStoredItem(name: OfferingQualifierType) {
    const value = this.storageService.get<string>(this.storageKeys[name]);
    return value && value.length > 0 ? value : null;
  }

  private static mapValue<TValue>(response: TValue): MaybeValueSuccess<TValue> {
    return {
      success: true,
      value: response,
    };
  }

  private static mapOffers(
    response: MaybeValueSuccess<OfferingResponseV4>,
  ): MaybeValueSuccess<OfferingResponseV4> {
    const { value } = response;
    const productGroups = value.productGroups.map((productGroup) => ({
      ...productGroup,
      products: productGroup.products.map(OfferingService.extendProduct),
    }));

    return {
      success: true,
      value: {
        ...value,
        productGroups,
      },
    };
  }

  private static extendProduct(product: OfferingProductV4): OfferingProductV4 {
    const pricePeriod = product.price.period;

    const isTermProduct = Boolean(product.subsequentPrice);
    const hasMonthlyBilling =
      pricePeriod.quantity === 1 && pricePeriod.unit === 'MONTH';
    const runsOneYear =
      product.contractPeriod?.quantity === 12 &&
      product.contractPeriod?.unit === 'MONTH';

    return {
      ...product,
      isTermProduct,
      hasMonthlyBilling,
      runsOneYear,
    };
  }

  private static mapError(
    errorResponse: HttpErrorResponse,
  ): Observable<MaybeValueError<any>> {
    return of({
      success: false,
      error: errorResponse.error?.title ?? 'offering.error.generic.error.name',
    });
  }
}

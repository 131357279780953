import { Inject, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { NowOverlayRef } from '../now-overlay.ref';
import { NowOverlayInterface } from '../interface/now-overlay.interface';
import { NowOverlayData } from '../now-overlay.data';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import {
  NowOverlayConfig,
  NOW_OVERLAY_CONFIG,
  NowOverlayToken,
} from '../now-overlay-config';

/**
 * Class Loading Overlays Service to Control Overlay Handling in App
 */
@Injectable({
  providedIn: 'root',
})
export class NowOverlayService {
  /**
   * Overlay Configuration Object
   */
  nowOverlayConfig: NowOverlayConfig;

  /**
   * Instance of angular CDK Overlay
   */
  private readonly cdkOverlayRef: OverlayRef;
  /**
   * Loading Overlay Reference
   */
  private readonly nowOverlayRef: NowOverlayRef;

  /**
   * Wire DI
   */
  constructor(
    @Inject(NOW_OVERLAY_CONFIG) token: NowOverlayToken,
    private overlay: Overlay,
    private _injector: Injector,
    private router: Router,
  ) {
    this.nowOverlayConfig = {
      ...token.default,
      ...token.config,
    };

    this.cdkOverlayRef = this.overlay.create();
    this.nowOverlayRef = new NowOverlayRef(this.cdkOverlayRef);

    // Close Overlay on Route Change
    /* istanbul ignore else */
    if (this.nowOverlayConfig.autoClose) {
      this.router.events.subscribe((event) => {
        if (
          event instanceof NavigationEnd ||
          event instanceof NavigationError
        ) {
          this.closeOverlay();
        }
      });
    }
  }

  /**
   * getInjector
   */
  private static getInjector(
    data: NowOverlayData,
    nowOverlayRef: NowOverlayRef,
    parentInjector: Injector,
  ) {
    const tokens = new WeakMap();

    tokens.set(NowOverlayData, data);
    tokens.set(NowOverlayRef, nowOverlayRef);

    return new PortalInjector(parentInjector, tokens);
  }

  /**
   * Shows the Overlay and return his instance to control your self
   */
  showOverlay(data: NowOverlayInterface): NowOverlayRef {
    const injector = NowOverlayService.getInjector(
      data,
      this.nowOverlayRef,
      this._injector,
    );

    const nowPortal = new ComponentPortal(
      // @ts-ignore
      this.nowOverlayConfig.overlayComponent,
      null,
      injector,
    );
    this.cdkOverlayRef.attach(nowPortal);

    return this.nowOverlayRef;
  }

  /**
   * Close the Overlay
   */
  closeOverlay() {
    this.nowOverlayRef.close();
  }
}

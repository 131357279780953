import { Injectable } from '@angular/core';
import { environment } from '@nowffc-environment/environment';

/**
 * Script Loader Service
 */
@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  /**
   * Scripte die geladen werden können
   */
  private readonly scripts: any = {
    billwerk: {
      loaded: false,
      src: environment.externalScripts.billwerk,
    },
    loyJoy: {
      loaded: false,
      src: environment.externalScripts.loyJoy,
    },
  };

  /**
   * load a script/file
   */
  loadScript(name: string): Promise<boolean> {
    return new Promise((resolve) => {
      /* istanbul ignore else */
      if (!this.scripts[name]) {
        resolve(false);
      }
      // resolve if already loaded
      /* istanbul ignore if */
      if (this.scripts[name].loaded) {
        resolve(true);
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src; // wir wollen nicht das laden des Scripts testen
        if (this.scripts[name].async) {
          script.async = true;
        }
        if (this.scripts[name].id) {
          script.id = this.scripts[name].id;
        }
        /* istanbul ignore next */ script.onload = () => {
          this.scripts[name].loaded = true;
          resolve(true);
        }; // wir wollen nicht das laden des Scripts testen
        /* istanbul ignore next */ script.onerror = () => resolve(false);
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }
}

import { Injectable } from '@angular/core';

interface StorageItem<T> {
  value: T;
  expiresAt?: number;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  set<T>(key: string, value: T, expiresAt?: Date) {
    const item: StorageItem<T> = { value, expiresAt: expiresAt?.getTime() };
    localStorage.setItem(key, JSON.stringify(item));
  }

  get<T>(key: string): T | null {
    return this.getItemWithExpiryCheck(key);
  }

  has(key: string): boolean {
    return !!this.getItemWithExpiryCheck(key);
  }

  private getItemWithExpiryCheck<T>(key: string) {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }

    const item: StorageItem<T> = JSON.parse(value);

    if (item.expiresAt && item.expiresAt <= Date.now()) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }
}

import { createReducer, on } from '@ngrx/store';
import { fromAuthentication } from '../actions';

export interface AuthenticationState {
  initialized: boolean;
}

export const initialState: AuthenticationState = {
  initialized: false,
};

export const reducer = createReducer(
  initialState,

  on(
    fromAuthentication.authenticateSuccess,
    fromAuthentication.authenticateFail,
    fromAuthentication.logout,
    (state): AuthenticationState => ({
      ...state,
      initialized: true,
    }),
  ),
);

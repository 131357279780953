import { Meta, Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@nowffc-environment/environment';

/**
 * MetaService
 */
@Injectable({
  providedIn: 'root',
})
export class MetaService {
  /**
   * Title used for routes with missing title
   */
  static readonly FALLBACK_TITLE = 'RTL+ Account';

  /**
   * Wire DI
   */
  constructor(
    private readonly translate: TranslateService,
    private readonly title: Title,
    private readonly meta: Meta,
  ) {}

  /**
   * sanitize route
   */
  private static sanitizeRoute(route: string): string {
    return route.substr(1).replace('/', '_');
  }

  /**
   * set title based on the route
   */
  public async setTitleByRoute(route: string): Promise<void> {
    route = MetaService.sanitizeRoute(route);

    const routeTranslationKey = `seo.${route}.title`;
    const parentRouteTranslationKey = `seo.${route.split('_')[0]}.title`;
    const fallbackTranslationKey = 'seo.default.title';
    this.title.setTitle(
      (await this.translateKey(routeTranslationKey)) ||
        (await this.translateKey(parentRouteTranslationKey)) ||
        (await this.translateKey(fallbackTranslationKey)) ||
        MetaService.FALLBACK_TITLE,
    );
  }

  /**
   * meta description based on the route
   */
  public async setMetaDescriptionByRoute(route: string): Promise<void> {
    route = MetaService.sanitizeRoute(route);

    const descriptionTranslationKey = `seo.${route}.description`;
    const parentDescriptionTranslationDescription = `seo.${
      route.split('_')[0]
    }.description`;

    const translatedDescription =
      (await this.translateKey(descriptionTranslationKey)) ||
      (await this.translateKey(parentDescriptionTranslationDescription));

    if (translatedDescription) {
      this.meta.addTag(
        {
          name: 'description',
          content: translatedDescription,
        },
        true,
      );
    }
  }

  /**
   * meta robots
   */
  public setRobots({ index, follow }: { index: boolean; follow?: boolean }) {
    // can be removed once we have basic auth
    if (environment.seo.preventIndexing) {
      index = false;
      follow = false;
    }

    let attributes: string[] = [
      index ? 'index' : 'noindex',
      follow || index ? 'follow' : 'nofollow',
    ];

    if (index) {
      attributes = attributes.concat([
        'noarchive',
        'max-snippet:-1',
        'max-image-preview:large',
        'max-video-preview:-1',
      ]);
    }

    this.meta.updateTag({
      name: 'robots',
      content: attributes.join(','),
    });
  }

  /**
   * reset meta robots to default
   */
  public resetRobots() {
    this.setRobots({ index: false, follow: true });
  }

  /**
   * tries to translate a translation keys and returns it
   */
  private async translateKey(translationKey: string): Promise<string | false> {
    const res = await this.translate.get(translationKey).toPromise();

    // translate.get returns the input-string if no translation was found
    if (!res || res === translationKey) {
      return false;
    }

    return res;
  }
}

import { Injectable } from '@angular/core';
import { AccessTokenPayload } from '@nowffc-shared/interfaces/accessTokenPayload';
import { UserPermissions } from '@nowffc-shared/interfaces/userPermissions';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { jwtDecode } from 'jwt-decode';
import { Observable, map } from 'rxjs';

/**
 * Authorization service for saving and checking permissions
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private readonly oidcSecurityService: OidcSecurityService) {}

  /**
   *
   * @returns UserPermissions from the access token
   */
  getPermissionsFromAccessToken(): Observable<UserPermissions | null> {
    return this.oidcSecurityService.getAccessToken().pipe(
      map((token) => {
        if (!token) {
          return null;
        } else {
          const permissions = jwtDecode<AccessTokenPayload>(token)?.permissions;
          if (!permissions) {
            return null;
          }
          return permissions;
        }
      }),
    );
  }

  /**
   *
   * @returns Boolean if the user has access to music content and null if no permissions were defined
   */
  canAccessMusic(): Observable<boolean | null> {
    return this.getPermissionsFromAccessToken().pipe(
      map((permissions) => {
        if (!permissions) {
          return null;
        }
        return permissions.music.accessMusicContent;
      }),
    );
  }

  /**
   *
   * @returns Boolean if the user has access to music content and null if no permissions were defined
   */
  canAccessAudiobooks(): Observable<boolean | null> {
    return this.getPermissionsFromAccessToken().pipe(
      map((permissions) => {
        if (!permissions) {
          return null;
        }
        return permissions.audiobooks.accessPrhAudiobooks;
      }),
    );
  }

  /**
   *
   * @returns Boolean if the fallback permissions are active
   */
  isFallback(): Observable<boolean | null> {
    return this.getPermissionsFromAccessToken().pipe(
      map((permissions) => {
        if (!permissions) {
          return null;
        }
        return Boolean(permissions.fallback);
      }),
    );
  }
}

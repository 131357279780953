import { KameleoonService } from '@cbc/ngx-kameleoon';
import { Injectable } from '@angular/core';
import { FeatureToggleService } from '@nowffc-shared/services/feature-toggle/feature-toggle.service';
import { ErrorsService } from '../../../errors/services/errors.service';
import { OFFERINGS_FEATURE_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';

/**
 * Kameleoon Service
 */
@Injectable({
  providedIn: 'root',
})
export class KameleoonInitializerService {
  /**
   * Wire DI
   */
  constructor(
    private readonly kameleoonService: KameleoonService,
    private readonly featureToggleService: FeatureToggleService,
    private readonly errorsService: ErrorsService,
  ) {}

  /**
   * Initializes Kameleoon
   */
  public async initializeKameleoon(): Promise<void> {
    return new Promise((resolve) => {
      this.featureToggleService
        .isEnabledCached(OFFERINGS_FEATURE_TOGGLES.KAMELEOON)
        .then((isEnabled) => {
          // load Kameleoon only if feature toggle is enabled
          if (isEnabled) {
            this.kameleoonService
              .load({
                onError: (error) => {
                  this.errorsService.logBugsnag(error, { severity: 'info' });
                },
                timeout: 3000,
              })
              .finally(resolve);
          } else {
            this.kameleoonService.disable();
            resolve();
          }
        });
    });
  }
}

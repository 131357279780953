import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { FeatureToggleService } from '@nowffc-shared/services/feature-toggle/feature-toggle.service';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';

import * as fromActions from '../actions';
import { IDENTITY_FEATURE_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';

@Injectable()
export class FeatureEffects {
  loadIsValidateEmailAddressExperimentEnabled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadIsValidateEmailAddressExperimentEnabled),
      exhaustMap(({ email }) =>
        from(
          this.featureToggleService.isEnabled(
            IDENTITY_FEATURE_TOGGLES.DO_NOT_ENFORCE_EMAIL_VALIDATION,
            email,
          ),
        ).pipe(
          map((enabled) =>
            fromActions.loadIsValidateEmailAddressExperimentEnabledSuccess({
              enabled,
            }),
          ),
          catchError(() =>
            of(fromActions.loadIsValidateEmailAddressExperimentEnabledFail()),
          ),
        ),
      ),
    );
  });

  loadRetrievePasswordViaKeycloakExperimentEnabled$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadIsRetrievePasswordViaKeycloakExperimentEnabled),
      exhaustMap(() =>
        from(
          this.featureToggleService.isEnabled(
            IDENTITY_FEATURE_TOGGLES.RECEIVE_PASSWORD_VIA_KEYCLOAK,
          ),
        ).pipe(
          map((enabled) =>
            fromActions.loadIsRetrievePasswordViaKeycloakExperimentEnabledSuccess(
              {
                enabled,
              },
            ),
          ),
          catchError(() =>
            of(
              fromActions.loadIsRetrievePasswordViaKeycloakExperimentEnabledFail(),
            ),
          ),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly featureToggleService: FeatureToggleService,
  ) {}
}

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

@Injectable({
  providedIn: 'root',
})
export class IsProfileReadyGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.auth.selectUserLoaded).pipe(
      filter((loaded) => loaded),
      switchMap(() =>
        this.store.select(fromStore.auth.selectIsBasicProfileCompleted).pipe(
          map((complete) => {
            if (complete) {
              return true;
            }

            if (route.data.targetPath) {
              this.redirectService.setRedirectUrl(route.data.targetPath);
            }

            console.log('IsProfileReadyGuard denied access', {
              route,
              state,
            });
            return this.router.createUrlTree([
              '/registrierung/account-vervollstaendigen',
            ]);
          }),
        ),
      ),
    );
  }
}

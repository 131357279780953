import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsService } from '../errors/services/errors.service';
import { Router } from '@angular/router';

/**
 * class HttpErrorInterceptorInterceptor
 */
@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Wire DI
   */
  constructor(
    private readonly errorsService: ErrorsService,
    private readonly router: Router,
  ) {}

  /**
   * Handle HttpErrorResponses
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 400 && error.error === 'invalid token') {
          this.errorsService.logToastr(
            error.status.toString(),
            'Oops, du bist offenbar mit einem falschen Token hier gelandet und wirst daher ausgeloggt!',
          );
          this.errorsService.logBugsnag('Invalid Token issuer detected', {
            addInfos: error,
            severity: 'warning',
          });
          this.router.navigate(['/logout']);
        }

        return throwError(error);
      }),
    );
  }
}

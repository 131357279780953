/* istanbul ignore file */
import { HandledException } from '@nowffc-shared/exceptions/handledException';

/**
 * BillwerkJSException class
 */
export class BillwerkJSException extends HandledException {
  /**
   * Constructor
   *
   */
  constructor(m: string, headline = '', message = '', reportToTracker = false) {
    super(m, headline, message);
    this.name = 'BillwerkJSException';
    this.reportToTracker = reportToTracker;
  }
}

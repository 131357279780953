import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SubscriberDataSignalsState } from '../signals/states/subscriber-data.signals-state';

@Injectable({
  providedIn: 'root',
})
export class HasSubscriberDataGuard {
  constructor(
    private readonly router: Router,
    private readonly subscriberDataSignalsState: SubscriberDataSignalsState,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    return this.checkSubscriberData();
  }

  async canActivateChild(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }

  async checkSubscriberData(): Promise<boolean | UrlTree> {
    try {
      await this.subscriberDataSignalsState.getSubscriberData();
      return true;
    } catch (err: any) {
      console.error('HasSubscriberDataGuard denied access reason=', err);
      return this.router.createUrlTree(['/fehler-paket']);
    }
  }
}

import { Injectable } from '@angular/core';
import {
  SourcePointConfig,
  SourcePointConsentService,
} from '@plus/sourcepoint-consent-manager';
import { Observable } from 'rxjs';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { Consent } from '@plus/tracking-model-web';
import { map } from 'rxjs/operators';

const SOURCEPOINT_CONFIG: SourcePointConfig = {
  accountId: 1239,
  privacyManagerId: 666719,
  baseEndpoint: 'https://sourcepoint.rtl.de',
  propertyHref: 'https://plus.rtl.de',
  joinHref: true,
};

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private readonly sourcePointConsentService: SourcePointConsentService;

  constructor(private readonly errorService: ErrorsService) {
    this.sourcePointConsentService = new SourcePointConsentService({
      ...SOURCEPOINT_CONFIG,
      onError: (error: Error) => {
        this.errorService.logBugsnag(error);
      },
    });
  }

  getSourcePointConsentService(): SourcePointConsentService {
    return this.sourcePointConsentService;
  }

  getConsentedAll$(): Observable<boolean> {
    return this.sourcePointConsentService.getCurrentConsent$().pipe(
      map((consent: Error | Consent | undefined) => {
        return !(consent instanceof Error) && (consent?.consentedAll ?? false);
      }),
    );
  }
}

import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAuthentication from './authentication.reducer';
import * as fromUser from './user.reducer';

export interface AuthState {
  authentication: fromAuthentication.AuthenticationState;
  user: fromUser.UserState;
}

export const reducers: ActionReducerMap<AuthState> = {
  authentication: fromAuthentication.reducer,
  user: fromUser.reducer,
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');

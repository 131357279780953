import { Injectable, NgZone } from '@angular/core';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { ToastrService } from 'ngx-toastr';
import { ErrorsDetail } from '@nowffc-shared/interfaces/errors-detail';
import Bugsnag from '@bugsnag/js';
import { environment } from '@nowffc-environment/environment';
import { EnvironmentName } from '@nowffc-shared/interfaces/environment';
import { ErrorSeverity } from '@nowffc-shared/types/error-severity';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

/**
 * class Errors Service
 */
@Injectable()
export class ErrorsService {
  /**
   * Fehler Details die durch die Applikation gesetzt werden können
   */
  private errorDetails: ErrorsDetail = {
    status: 0,
    description: '',
    title: '',
  };

  /**
   * Wire DI
   */
  constructor(
    private readonly store: Store,
    private readonly windowRef: WindowRef,
    private readonly toastr: ToastrService,
    private readonly zone: NgZone,
  ) {
    this.resetErrorDetails();

    /* istanbul ignore next */
    if (!environment.production) {
      return;
    }

    const enabledReleaseStages: EnvironmentName[] = ['preprod', 'prod'];
    // istanbul ignore next
    Bugsnag.start({
      apiKey: environment.bugsnag.key,
      collectUserIp: false,
      releaseStage: environment.bugsnag.releaseStage,
      enabledReleaseStages,
      endpoints: environment.bugsnag.endpoints,
      appVersion: this.windowRef.nativeWindow.ffcAppVersion,
      onError: () => {
        this.store
          .select(fromStore.auth.selectUserId)
          .pipe(
            tap((uid) => {
              if (uid) {
                Bugsnag.setUser(uid.toString());
              }
            }),
          )
          .subscribe();
      },
    });
  }

  /**
   * get the Error Details for Error View Component
   */
  getErrorDetails(): ErrorsDetail {
    return this.errorDetails;
  }

  /**
   * set the Error Details for Error View Component
   */
  setErrorDetails(title: string, desc: string, status: number) {
    this.errorDetails.title = title;
    this.errorDetails.description = desc;
    this.errorDetails.status = status;
  }

  /**
   * log Error to Toastr
   */
  logToastr(message: string, name: string, enforceAcknowledgement = false) {
    this.zone.run(() => {
      this.toastr.error(message, name, {
        enableHtml: true,
        disableTimeOut: enforceAcknowledgement,
      });
    });
  }

  /**
   * log Error to Bugsnag
   */
  logBugsnag(
    error: any,
    options: { severity?: ErrorSeverity; addInfos?: object } = {},
  ) {
    const { addInfos } = options;
    const severity = options.severity || 'error';

    if (!environment.production) {
      switch (severity) {
        case 'warning':
          if (addInfos) {
            console.warn(error, addInfos);
          } else {
            console.warn(error);
          }
          break;
        case 'error':
          if (addInfos) {
            console.error(error, addInfos);
          } else {
            console.error(error);
          }
          break;
        default:
          if (addInfos) {
            console.log(error, addInfos);
          } else {
            console.log(error);
          }
          break;
      }

      return;
    }

    Bugsnag.notify(error, (event) => {
      event.severity = severity;
      event.addMetadata('custom', {
        addInfos,
        angular: !error.ngDebugContext
          ? undefined
          : {
              component: error.ngDebugContext.component,
              context: error.ngDebugContext.context,
            },
      });
    });
  }

  /**
   * log Error to console
   */
  logConsole(error: any) {
    // Log the error to the console
    console.error(error);
  }

  /**
   * Reset Error Details
   */
  private resetErrorDetails() {
    this.errorDetails = {
      status: 0,
      description: '',
      title: '',
    };
  }
}

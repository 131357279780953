import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@nowffc-environment/environment';
import { FeatureToggleResponse } from '@nowffc-shared/interfaces/response/feature-toggle';

/**
 * Feature Toggle Service
 */
@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  /**
   * local cache
   */
  cache: any = {};
  /**
   * Wire DI
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * returns value of a setting
   */
  async isEnabled(feature: string, emailAddress = ''): Promise<boolean> {
    try {
      const featureToggle = (await this.http
        .post(`${environment.bffUrl}/featureToggle`, {
          feature,
          emailAddress,
        })
        .toPromise()) as FeatureToggleResponse;

      return featureToggle.enabled;
    } catch (e) {
      console.error(e, feature);
      return false;
    }
  }

  /**
   * returns value of a setting with limited caching
   */
  async isEnabledCached(feature: string, emailAddress = ''): Promise<boolean> {
    const currentTime = new Date();
    const cacheKey = `${feature}%${emailAddress}`;

    if (
      this.cache[cacheKey] &&
      this.cache[cacheKey].eol > currentTime &&
      typeof this.cache[cacheKey].value === 'boolean'
    ) {
      return this.cache[cacheKey].value;
    } else {
      const isEnabled = await this.isEnabled(feature, emailAddress);
      this.cache[cacheKey] = {
        eol: new Date(currentTime.getTime() + 2 * 60 * 1000),
        value: isEnabled,
      };

      return isEnabled;
    }
  }

  /**
   * clears cache of all toggles
   */
  clearCache(): void {
    this.cache = {};
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { ToastrService } from 'ngx-toastr';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { Event, NavigationStart, Router } from '@angular/router';
import { environment } from '@nowffc-environment/environment';
import { firstValueFrom } from 'rxjs';

/**
 * VersionCheckService Service
 */
@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  /**
   * Caching time in milliseconds
   * Current 5 minutes
   */
  private static readonly cacheTime = 1000 * 60 * 5;

  /**
   * How long will we wait for a hard page reload after first notification
   * Current 5 minutes
   */
  private static readonly waitUntilForReload = 1000 * 60 * 5;

  /**
   * Current App Version
   */
  private readonly currentAppVersion: string;

  /**
   * Latest version on server
   */
  private latestAppVersion?: string;

  /**
   * Timestamp Last Version File Check
   */
  private timestampLastCheck = 0;

  /**
   * Wann haben wir den User mit dem Toastr über die neue Version informiert
   */
  private timestampUserInformed = 0;

  /**
   * Wire DI
   */
  constructor(
    private readonly http: HttpClient,
    private readonly windowRef: WindowRef,
    private readonly toastr: ToastrService,
    private readonly errorsService: ErrorsService,
    private readonly router: Router,
  ) {
    this.currentAppVersion = this.windowRef.nativeWindow.ffcAppVersion;

    this.router.events.subscribe(async (event: Event) => {
      if (event instanceof NavigationStart) {
        this.hasVersionChanged();
      }
    });
  }

  /**
   * check the version.
   * if true a new version is available
   */
  public async hasVersionChanged(): Promise<boolean> {
    // Wenn Cache abgelaufen ist, hole neue Version
    if (
      this.timestampLastCheck + VersionCheckService.cacheTime < Date.now() &&
      !(await this.fetchVersionFile())
    ) {
      return false;
    }

    // latest version is in use
    if (
      !this.latestAppVersion ||
      this.latestAppVersion === this.currentAppVersion
    ) {
      this.timestampUserInformed = 0;
      return false;
    }

    // user should update
    if (this.timestampUserInformed === 0) {
      this.timestampUserInformed = Date.now();
    }

    this.reloadApp();

    this.toastr.info(
      'maintenance.newversion.message',
      'maintenance.newversion.title',
      { positionClass: 'toast-bottom-center' },
    );

    return true;
  }

  private reloadApp(): void {
    if (
      this.timestampUserInformed + VersionCheckService.waitUntilForReload <
      Date.now()
    ) {
      location.reload();
    }
  }

  /**
   * fetch the current version file from server
   * and return true if succeed
   */
  private async fetchVersionFile(): Promise<boolean> {
    if (!environment.production) {
      return false;
    }

    const rand = Math.floor(Math.random() * 100000000);
    return firstValueFrom(
      this.http.get(`/VERSION?r=${rand}`, {
        responseType: 'text',
      }),
    ).then(
      (res) => {
        this.latestAppVersion = res.trim();
        this.timestampLastCheck = Date.now();

        return true;
      },
      (err) => {
        this.timestampLastCheck = 0;
        this.errorsService.logBugsnag(err, {
          severity: 'info',
          addInfos: {
            context: 'Version File could not be received',
          },
        });

        return false;
      },
    );
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@nowffc-environment/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User, UserPersonalInformation } from '@nowffc-shared/interfaces/user';
import { formatISO, parseISO } from 'date-fns';
import { Gender } from '@nowffc-shared/types/gender';
import { Login } from '@nowffc-shared/interfaces/login';
import { UpdateUser } from '@nowffc-shared/interfaces/update-user';
import { JwtService } from '@nowffc-shared/services/jwt/jwt.service';
import { UserToken } from '@nowffc-shared/interfaces/user-token';

interface UserDto {
  email: string;
  emailVerified: boolean;

  gender: Gender;
  firstname: string;
  lastname: string;
  dateOfBirth?: string;
  country: string;
}

interface UserUpdateRequest {
  firstname?: string;
  lastname?: string;
  gender?: Gender;
  zipcode?: string;
  email?: string;
  address?: string;
  city?: string;
  dateOfBirth?: string;
  country?: string;
  login?: Login;
  partnerProcess?: boolean;
}

interface ViewerCountry {
  countryCode: string;
}

/**
 * User service provides methods for updating user
 */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private readonly http: HttpClient,
    private readonly jwtService: JwtService,
  ) {}

  private static mapBffResponseToUserObject(res: UserDto): User {
    return {
      email: res.email,
      emailVerified: res.emailVerified,
      gender: res.gender,
      firstname: res.firstname,
      lastname: res.lastname,
      dateOfBirth: res.dateOfBirth ? parseISO(res.dateOfBirth) : undefined,
      country: res.country,
    };
  }

  load(): Observable<UserPersonalInformation> {
    return this.http.get<UserDto>(`${environment.bffUrl}/personalInfo`).pipe(
      map((data) => {
        return UserService.mapBffResponseToUserObject(data);
      }),
      catchError((err: HttpErrorResponse) =>
        throwError(() => ({
          message:
            err.error?.title || 'userServiceError.couldNotLoad.personalInfo',
        })),
      ),
    );
  }

  update(newUser: UpdateUser): Observable<User> {
    const userUpdateRequest: UserUpdateRequest = {
      gender: newUser.gender,
      firstname: newUser.firstname,
      lastname: newUser.lastname,
      dateOfBirth: newUser.dateOfBirth
        ? formatISO(newUser.dateOfBirth, {
            representation: 'date',
          })
        : undefined,
      country: newUser.country,
      partnerProcess: newUser.partnerProcess,
    };

    return this.http
      .patch<UserDto>(`${environment.bffUrl}/personalInfo`, userUpdateRequest)
      .pipe(
        map((data) => UserService.mapBffResponseToUserObject(data)),
        catchError((err: HttpErrorResponse) =>
          throwError(() => ({
            message:
              err.error.title || 'userServiceError.couldNotPatch.personalInfo',
          })),
        ),
      );
  }

  async getUsersEstimatedCountry(): Promise<string> {
    return this.http
      .get<ViewerCountry>(`${environment.bffUrl}/clientInformation`)
      .toPromise()
      .then((res) => {
        return res?.countryCode || 'DE';
      })
      .catch(() => {
        return 'DE';
      });
  }

  async invalidateToken(): Promise<boolean> {
    return this.http
      .delete(`${environment.bffUrl}/invalidateToken`)
      .toPromise()
      .then(
        () => {
          return true;
        },
        () => {
          return false;
        },
      );
  }

  storeAccessToken(accessToken: string) {
    localStorage.setItem('ffc-jwt', accessToken);
  }

  getAccessToken() {
    return localStorage.getItem('ffc-jwt');
  }

  clearAccessToken() {
    localStorage.removeItem('ffc-jwt');
  }

  getUserToken(nowJwt: string): UserToken {
    return this.jwtService.decodeToken(nowJwt);
  }
}

/* istanbul ignore file */
/**
 * Exception class
 */
export class HandledException extends Error {
  /**
   * Show Message to User via Toastr
   */
  showInToastr = false;

  /**
   * Soll die Exception nach Bugsnag geschickt werden
   */
  reportToTracker = false;

  /**
   * Übersetzungstring für den MessageService
   */
  userHeadline = '';

  /**
   * Übersetzungstring für den MessageService
   */
  userMessageKey = '';

  /**
   * Constructor
   *
   * @param headline must be a translation string
   * @param message must be a translation string
   */
  constructor(m: string, headline = '', message = '') {
    super(m);

    if (headline.length > 0 && message.length > 0) {
      this.showInToastr = true;
      this.userHeadline = headline;
      this.userMessageKey = message;
    }
  }
}

import { createAction, props } from '@ngrx/store';
import { User } from '@nowffc-shared/interfaces/user';
import { UpdateUser } from '@nowffc-shared/interfaces/update-user';

// load user
export const loadUser = createAction('[Auth] Load User');
export const loadUserFail = createAction(
  '[Auth] Load User Fail',
  props<{ error: { message: string } }>(),
);
export const loadUserSuccess = createAction(
  '[Auth] Load User Success',
  props<{ user: User }>(),
);

// update user
export const updateUser = createAction(
  '[Auth] Update User',
  props<{ user: UpdateUser; redirectTarget: string }>(),
);
export const updateUserFail = createAction(
  '[Auth] Update User Fail',
  props<{ error: { message: string } }>(),
);
export const updateUserSuccess = createAction(
  '[Auth] Update User Success',
  props<{ user: User; redirectTarget?: string }>(),
);

// resend validation email
export const resendValidationEmail = createAction(
  '[Auth] Resend Validation Email',
);
export const resendValidationEmailFail = createAction(
  '[Auth] Resend Validation Email Fail',
);
export const resendValidationEmailSuccess = createAction(
  '[Auth] Resend Validation Email Success',
);

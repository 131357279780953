<now-header
  *ngIf="showHeader"
  [isLoggedIn]="isLoggedIn$ | async"
  [originUrl]="originUrl$ | async"
  [showMenu]="showMenu$ | async"
  (toLoginClicked)="handleToLoginClicked($event)"
  (logoClicked)="handleLogoClick($event)"
  role="banner"
></now-header>
<main [attr.data-router-slug]="routerSlug">
  <router-outlet *ngIf="isReady"></router-outlet>
  <now-loading-spinner *ngIf="!isReady"></now-loading-spinner>
</main>
<now-footer *ngIf="showFooter" role="contentinfo"></now-footer>
<now-braze-in-app-messages></now-braze-in-app-messages>

import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'now-loading-spinner',
  template: '',
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  spinnerElem: HTMLElement | null;

  ngOnInit() {
    this.spinnerElem = document.getElementById('loading-spinner');
    this.spinnerElem?.classList.remove('hide');
  }

  ngOnDestroy() {
    this.spinnerElem?.classList.add('hide');
    const textElem = this.spinnerElem?.getElementsByClassName(
      'loading-spinner__text',
    )[0];
    if (textElem) {
      this.spinnerElem?.removeChild(textElem);
    }
  }
}

import { OverlayRef } from '@angular/cdk/overlay';

/**
 * Loading Overlay Reference
 */
export class NowOverlayRef {
  /**
   * Wire DI
   */
  constructor(readonly _overlayRef: OverlayRef) {}

  /**
   * Detach Overlay from DOM
   */
  close() {
    this._overlayRef.detach();
  }
}

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const selectUserState = createSelector(
  fromFeature.selectAuthState,
  (state) => state.user,
);

export const selectUserEntity = createSelector(
  selectUserState,
  (state) => state.entity,
);

export const selectUserLoading = createSelector(
  selectUserState,
  (state) => state.loading,
);

export const selectUserLoaded = createSelector(
  selectUserState,
  (state) => state.loaded,
);

export const selectUserError = createSelector(
  selectUserState,
  (state) => state.error,
);

export const selectIsEmailVerified = createSelector(
  selectUserEntity,
  (user) => user?.emailVerified || false,
);

export const selectUserEmail = createSelector(
  selectUserEntity,
  (user) => user?.email,
);

export const selectIsBasicProfileCompleted = createSelector(
  selectUserEntity,
  (user) =>
    !!(user?.firstname && user?.lastname && user?.dateOfBirth && user?.country),
);

export const selectIsUserLoaded = createSelector(
  selectUserState,
  (user) => user?.loaded,
);

export const selectUserId = createSelector(
  selectUserEntity,
  (user) => user?.uid,
);

export const selectAccountPersonalizationId = createSelector(
  selectUserEntity,
  (user) => user?.accountPersonalizationId,
);

export const selectIsUserOnDeletionQueue = createSelector(
  selectUserEntity,
  (state) => !!state?.deletionDate,
);

import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromStore from '@nowffc-state/store';

@Injectable({
  providedIn: 'root',
})
export class IsVerifiedGuard {
  constructor(
    private readonly store: Store,
    private readonly redirectService: RedirectService,
    private readonly router: Router,
  ) {}

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.checkStore(state).pipe(
      switchMap((response) => of(response)),
      catchError(() => of(false)),
    );
  }

  /**
   * Interface that a class can implement to be a guard deciding if a child route can be activated
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  checkStore(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(fromStore.auth.selectUserState).pipe(
      tap(({ loaded, loading }) => {
        if (!loaded && !loading) {
          this.store.dispatch(fromStore.auth.loadUser());
        }
      }),
      filter(({ loaded }) => loaded),
      take(1),
      switchMap(() =>
        this.store
          .select(fromStore.auth.selectUserEntity)
          .pipe(
            mergeMap((user) =>
              user?.emailVerified
                ? of(true)
                : this.checkExperiment(state, user!.email!),
            ),
          ),
      ),
    );
  }

  checkExperiment(
    state: RouterStateSnapshot,
    email: string,
  ): Observable<boolean | UrlTree> {
    return this.store
      .select(
        fromStore.experiments.selectIsValidateEmailAddressExperimentLoaded,
      )
      .pipe(
        tap((loaded) => {
          if (!loaded) {
            this.store.dispatch(
              fromStore.experiments.loadIsValidateEmailAddressExperimentEnabled(
                { email },
              ),
            );
          }
        }),
        filter((loaded) => loaded),
        take(1),
        switchMap(() =>
          this.store
            .select(
              fromStore.experiments
                .selectIsValidateEmailAddressExperimentEnabled,
            )
            .pipe(
              map((isEnabled) => {
                if (isEnabled) {
                  return true;
                }

                console.log('IsVerifiedGuard denied access', { state });
                this.redirectService.setRedirectUrl(state.url);
                return this.router.createUrlTree(['/registrierung/validieren']);
              }),
            ),
        ),
      );
  }
}

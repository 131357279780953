import { NgModule } from '@angular/core';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@nowffc-environment/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { ExperimentsStoreModule } from './experiments/experiments-store.module';
import { AuthStoreModule } from './auth/auth-store.module';

export const metaReducers: MetaReducer[] = !environment.production
  ? [storeFreeze]
  : [];

@NgModule({
  declarations: [],
  imports: [
    // Root Store
    StoreModule.forRoot({}, { metaReducers }),
    EffectsModule.forRoot(),

    // Feature Store Modules
    AuthStoreModule,
    ExperimentsStoreModule,

    // Dev Tooling
    !environment.production ? StoreDevtoolsModule.instrument() : [],
  ],
})
export class RootStoreModule {}

import { IToggle } from 'unleash-proxy-client';
import {
  FactoryProvider,
  inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '@nowffc-environment/environment';

/**
 * InjectionToken used to register multiple ExperimentStateProcessorFn implementations
 *
 * @see ExperimentStateProcessorFn
 */
export const EXPERIMENT_STATE_PROCESSOR_FNS = new InjectionToken<
  readonly ExperimentStateProcessorFn[]
>('EXPERIMENT_STATE_PROCESSOR_FNS');

/**
 * Functional interface for filtering active experiments from Unleash.
 * Implementation must return an Observable that emits at least one array of toggles, otherwise the filter chain
 * will run into a timeout.
 *
 * They can be registered als DI multi provider.
 *
 * @see EXPERIMENT_STATE_PROCESSOR_FNS
 */
export type ExperimentStateProcessorFn = (
  experiments: IToggle[],
) => Observable<IToggle[]>;

/**
 * ExperimentStateProcessorFn that just runs the input.
 */
export const NO_OP_EXPERIMENT_PROCESSOR: ExperimentStateProcessorFn = (
  toggles,
) => of(toggles);

/**
 * ExperimentStateProcessorFn that logs all supplied toggles with their active variant for debug purposes.
 */
export const LOGGING_EXPERIMENT_PROCESSOR: ExperimentStateProcessorFn = (
  toggles,
) => {
  console.group('Returning experiments');
  console.table(
    toggles.map((toggle) => ({
      name: toggle.name,
      variant: toggle.variant.name,
      variantEnabled: toggle.variant.enabled,
    })),
  );
  console.groupEnd();
  return of(toggles);
};

/**
 * Creates a provider for the supplied ExperimentStateProcessorFn
 */
export function provideExperimentProcessor(
  processorFactory: () => ExperimentStateProcessorFn,
): FactoryProvider {
  return {
    provide: EXPERIMENT_STATE_PROCESSOR_FNS,
    useFactory: processorFactory,
    multi: true,
  };
}

/**
 * Creates a provider for the LOGGING_EXPERIMENT_PROCESSOR.
 * Falls back to NO_OP_EXPERIMENT_PROCESSOR on production.
 */
export function provideUnleashExperimentLogger(): FactoryProvider {
  return provideExperimentProcessor(() =>
    environment.production
      ? NO_OP_EXPERIMENT_PROCESSOR
      : LOGGING_EXPERIMENT_PROCESSOR,
  );
}

@Injectable({ providedIn: 'root' })
export class UnleashExperimentProcessorService {
  private readonly experimentStateProcessorFns =
    inject(EXPERIMENT_STATE_PROCESSOR_FNS, { optional: true }) ?? [];

  public processExperiments(toggles: IToggle[]) {
    return this.experimentStateProcessorFns.reduce(
      (observableChain, current) =>
        observableChain.pipe(
          switchMap((currentToggles) => current(currentToggles).pipe(take(1))),
        ),
      of(toggles),
    );
  }
}

import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const selectFeatureState = createSelector(
  fromFeature.selectFeatureToggleState,
  (state) => state.features,
);

// ValidateEmailAddressExperiment
export const selectIsValidateEmailAddressExperimentLoaded = createSelector(
  selectFeatureState,
  (state) =>
    state.selectIsValidateEmailAddressExperimentEnabled?.loaded || false,
);

export const selectIsValidateEmailAddressExperimentEnabled = createSelector(
  selectFeatureState,
  (state) =>
    state.selectIsValidateEmailAddressExperimentEnabled?.enabled || false,
);

// RetrievePasswordViaKeycloakExperiment
export const selectIsRetrievePasswordViaKeycloakExperimentLoaded =
  createSelector(
    selectFeatureState,
    (state) =>
      state.selectIsRetrievePasswordViaKeycloakExperimentEnabled?.loaded ||
      false,
  );

export const selectIsRetrievePasswordViaKeycloakExperimentEnabled =
  createSelector(
    selectFeatureState,
    (state) =>
      state.selectIsRetrievePasswordViaKeycloakExperimentEnabled?.enabled ||
      false,
  );

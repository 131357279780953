import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@nowffc-environment/environment';
import { AppConfigResponse } from '@nowffc-shared/interfaces/response/app-config';

/**
 * App Config Service
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  /**
   * local cache
   */
  cache: any = {};

  /**
   * Wire DI
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * returns app config from BFF
   */
  async getAppConfig(): Promise<{
    success: boolean;
    value?: AppConfigResponse;
  }> {
    try {
      const appConfig = (await this.http
        .get(`${environment.bffUrl}/appConfig`)
        .toPromise()) as AppConfigResponse;

      return { success: true, value: appConfig };
    } catch (e) {
      console.error(e);
      return { success: false, value: undefined };
    }
  }

  /**
   * returns app config from cache or BFF
   */
  async getAppConfigCached(): Promise<{
    success: boolean;
    value?: AppConfigResponse;
  }> {
    const currentTime = new Date();
    const cacheKey = 'AppConfig';

    if (this.cache[cacheKey] && this.cache[cacheKey].eol > currentTime) {
      return { success: true, value: this.cache[cacheKey].value };
    } else {
      this.cache[cacheKey] = {};
      const res = await this.getAppConfig();
      if (res.success) {
        this.cache[cacheKey].eol = new Date(currentTime.getTime() + 10 * 1000);
        this.cache[cacheKey].value = res.value;
      }

      return res;
    }
  }

  /**
   * returns the modules app config
   */
  async isModuleEnabled(name: string): Promise<boolean> {
    const res = await this.getAppConfigCached();
    if (res.success) {
      const appConfig: any = res.value;
      if (appConfig?.modules?.hasOwnProperty(name)) {
        return appConfig.modules[name];
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  /**
   * clears cache of all toggles
   */
  clearCache(): void {
    this.cache = {};
  }
}

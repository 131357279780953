import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

/**
 * needed for cache busting
 * There is an issue on GitHub for ngx-translate/http-loader which is about cache invalidation
 * follow the link for more information https://github.com/ngx-translate/http-loader/issues/25
 */
export class LazyTranslateLoader implements TranslateLoader {
  /**
   * loads the translation file
   */
  getTranslation(lang: string): Observable<any> {
    return from(import(`../../../assets/i18n/${lang}.json`));
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import {
  IConfig,
  IMutableContext,
  InMemoryStorageProvider,
  IToggle,
  UnleashClient,
} from 'unleash-proxy-client';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  fromEvent,
  Observable,
  of,
  ReplaySubject,
  scan,
  Subscription,
  switchMap,
  TimeoutError,
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  timeout,
} from 'rxjs/operators';
import { environment } from '@nowffc-environment/environment';
import { FeatureToggles } from '@plus/unified-tracking-sdk';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { EXPERIMENT_TOGGLES } from '@nowffc-shared/services/feature-toggle/unleash.types';
import { UnleashExperimentProcessorService } from '@nowffc-shared/services/feature-toggle/unleash-experiment-processor.service';

export const enum UnleashState {
  Loading = 'loading',
  Healthy = 'healthy',
  Error = 'error',
  Timeout = 'timeout',
}

export interface Variant {
  name: string;
  enabled: boolean;
  payload?: {
    type: string;
    value: string;
  };
}

export const TIMEOUT_VARIANT: Variant = {
  name: 'error_timeout',
  enabled: true,
};

export const NO_CONTEXT_VARIANT: Variant = {
  name: 'error_no_context',
  enabled: true,
};

export const LOADING_ERROR_VARIANT: Variant = {
  name: 'error_loading_toggles',
  enabled: true,
};

export const EXPERIMENT_NOT_FOUND_VARIANT: Variant = {
  name: 'error_variant_not_found',
  enabled: true,
};

/**
 * More direct connection with Unleash service via Unleash proxy instead of
 * using the ffc-bff like the FeatureToggleService does.
 */
@Injectable({ providedIn: 'root' })
export class UnleashService implements OnDestroy {
  private readonly timeout = environment.unleash.timeoutInMs;
  private readonly filterTimeout = environment.unleash.filterTimeoutInMs;
  private readonly unleashClient: UnleashClient;
  private readonly trackingClientId = new ReplaySubject<string>(1);
  private readonly contextAvailable$: Observable<boolean> =
    this.trackingClientId.pipe(
      switchMap((clientId) => this.getUnleashContext(clientId)),
      switchMap((context) => this.unleashClient.updateContext(context)),
      map(() => true),
      catchError(() => of(false)),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
  private readonly subscriptions: Subscription = new Subscription();
  private readonly stateSubject = new BehaviorSubject(UnleashState.Loading);
  private readonly state$ = this.stateSubject.pipe(
    scan((last, current) => {
      // Whenever we are still 'loading' and encounter a 'timeout', we switch to state 'timeout'
      if (last === UnleashState.Loading && current === UnleashState.Timeout) {
        return UnleashState.Timeout;
      }

      // In all other cases we want to keep the state we are currently in
      if (current === UnleashState.Timeout) {
        return last;
      }

      return current;
    }),
    distinctUntilChanged(),
    filter((state) => state !== UnleashState.Loading),
    shareReplay({
      bufferSize: 1,
      refCount: false,
    }),
  );

  constructor(
    private readonly windowRef: WindowRef,
    private readonly experimentProcessor: UnleashExperimentProcessorService,
  ) {
    const config: IConfig = {
      ...environment.unleash,
      storageProvider: new InMemoryStorageProvider(),
    };
    this.unleashClient = new UnleashClient(config);

    this.subscriptions.add(
      fromEvent<Error>(this.unleashClient, 'error').subscribe((err) => {
        this.stateSubject.next(UnleashState.Error);
        console.error(`UnleashClient reported an error: ${err}`);
      }),
    );

    this.subscriptions.add(
      fromEvent<void>(this.unleashClient, 'recovered').subscribe(() => {
        this.stateSubject.next(UnleashState.Healthy);
      }),
    );

    this.subscriptions.add(
      fromEvent<void>(this.unleashClient, 'ready').subscribe(() => {
        this.stateSubject.next(UnleashState.Healthy);
      }),
    );
  }

  public async init() {
    try {
      setTimeout(() => {
        this.stateSubject.next(UnleashState.Timeout);
      }, this.timeout);

      await this.unleashClient.start();
    } catch (err) {
      this.stateSubject.next(UnleashState.Error);
      console.error(`UnleashClient reported an error: ${err}`);
    }
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public isEnabled(toggleName: string, fallback = false): Observable<boolean> {
    return this.state$.pipe(
      map((state) => {
        if (state === UnleashState.Timeout || state === UnleashState.Error) {
          return fallback;
        }

        return this.unleashClient.isEnabled(toggleName);
      }),
    );
  }

  public allEnabled(toggleNames: string[]): Observable<boolean> {
    return this.checkMultiple(toggleNames, (toggleName) =>
      this.unleashClient.isEnabled(toggleName),
    );
  }

  public allDisabled(toggleNames: string[]): Observable<boolean> {
    return this.checkMultiple(
      toggleNames,
      (toggleName) => !this.unleashClient.isEnabled(toggleName),
    );
  }

  public getVariant(toggleName: string): Observable<Variant> {
    return combineLatest([this.state$, this.contextAvailable$]).pipe(
      map(([state, contextAvailable]) => {
        if (state === UnleashState.Timeout || this.isCypressTest()) {
          return { variant: TIMEOUT_VARIANT, isError: true };
        }

        if (!contextAvailable) {
          return { variant: NO_CONTEXT_VARIANT, isError: true };
        }

        if (state === UnleashState.Error) {
          return { variant: LOADING_ERROR_VARIANT, isError: true };
        }

        const variant = this.unleashClient.getVariant(toggleName);

        if (!variant.enabled) {
          const message = `Variant '${toggleName}' not found or is not active`;
          console.error(message);
          return { variant: EXPERIMENT_NOT_FOUND_VARIANT, isError: true };
        }

        return {
          variant: {
            name: variant.name,
            enabled: variant.enabled,
            payload: variant.payload,
          },
          isError: false,
        };
      }),
      switchMap(({ variant, isError }) => {
        const toggles = [
          {
            name: toggleName,
            enabled: true,
            variant,
            impressionData: false,
          },
        ];
        return isError
          ? of(toggles)
          : this.processExperiments(toggles, () => [
              {
                name: toggleName,
                enabled: true,
                impressionData: false,
                variant: TIMEOUT_VARIANT,
              },
            ]);
      }),
      map((toggles) => toggles?.[0]?.variant ?? EXPERIMENT_NOT_FOUND_VARIANT),
    );
  }

  public async getAllExperiments(): Promise<FeatureToggles> {
    return firstValueFrom(
      combineLatest([this.state$, this.contextAvailable$]).pipe(
        map(([state, contextAvailable]) => {
          if (!contextAvailable || state !== UnleashState.Healthy) {
            return [];
          }

          return this.unleashClient
            .getAllToggles()
            .filter((toggle) => toggle.enabled)
            .filter((toggle) => Boolean(toggle.variant))
            .filter((toggle) => toggle.variant.enabled)
            .filter((toggle) => EXPERIMENT_TOGGLES.includes(toggle.name));
        }),
        switchMap((toggles) =>
          toggles.length === 0
            ? of(toggles)
            : this.processExperiments(toggles, () => []),
        ),
        map((toggles) => ({
          experiments: toggles.map((toggle) => ({
            name: toggle.name,
            variant: toggle.variant.name,
          })),
        })),
      ),
    );
  }

  private processExperiments(
    toggles: IToggle[],
    fallbackValueForTimeout: () => IToggle[],
  ) {
    return this.experimentProcessor.processExperiments(toggles).pipe(
      timeout(this.filterTimeout),
      catchError((err) => {
        if (err instanceof TimeoutError) {
          console.warn('Experiment processors execution took too long');
          return of(fallbackValueForTimeout());
        }
        throw err;
      }),
    );
  }

  public setClientId(clientId: string) {
    if (!clientId) {
      this.trackingClientId.error('No clientId available');
    } else {
      this.trackingClientId.next(clientId);
    }
  }

  private async getUnleashContext(clientId: string): Promise<IMutableContext> {
    return {
      userId: clientId,
    };
  }

  private checkMultiple(
    toggleNames: string[],
    check: (name: string) => boolean,
  ) {
    return this.state$.pipe(
      map((state) => {
        if (state === UnleashState.Timeout || state === UnleashState.Error) {
          return false;
        }

        return toggleNames.every(check);
      }),
    );
  }

  // See https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
  private isCypressTest(): boolean {
    return 'Cypress' in this.windowRef;
  }
}

import { Injectable } from '@angular/core';
import { SignalsStateService } from '../signals.state.service';
import { SubscriberData } from '@nowffc-shared/interfaces/subscriber-data/subscriber-data';
import { SubscriptionService } from '@nowffc-shared/services/subscription/subscription.service';
import { ErrorsService } from '@nowffc-errors/services/errors.service';
import { isError, MaybeValue } from '@nowffc-shared/types/maybe-value';
import { GenericError } from '@nowffc-shared/types/generic-error';

@Injectable({
  providedIn: 'root',
})
export class SubscriberDataSignalsState extends SignalsStateService<SubscriberData> {
  constructor(
    private readonly subscriptionService: SubscriptionService,
    private readonly errorsService: ErrorsService,
  ) {
    super();
  }

  subscriberDataPromise?: Promise<MaybeValue<SubscriberData, GenericError>>;

  protected async init() {
    if (this.subscriberDataPromise) {
      return this.subscriberDataPromise;
    }

    this.subscriberDataPromise = this.subscriptionService.getSubscriberData();
    return this.subscriberDataPromise.then((res) => {
      if (isError(res)) {
        this.errorsService.logBugsnag(res.error);
        throw new Error('subscriber.data.generic.loading.error');
      } else {
        this.setState(res.value);
      }
    });
  }

  public async getSubscriberData() {
    if (!this.isInitialized()) {
      await this.init();
    }

    return this.getState();
  }

  public async refreshSubscriberData() {
    this.subscriberDataPromise = undefined;
    await this.init();
  }
}

import { createReducer, on } from '@ngrx/store';
import { fromAuthentication } from '@nowffc-state/auth';
import * as fromExperiment from '../../experiments/actions';

export interface FeatureState {
  [id: string]: {
    loaded: boolean;
    enabled: boolean;
  };
}

const initialState: FeatureState = {};

export const reducer = createReducer(
  initialState,

  // ValidateEmailAddressExperiment
  on(
    fromExperiment.loadIsValidateEmailAddressExperimentEnabled,
    (state): FeatureState => ({
      ...state,
      ['selectIsValidateEmailAddressExperimentEnabled']: {
        loaded: false,
        enabled: false,
      },
    }),
  ),
  on(
    fromExperiment.loadIsValidateEmailAddressExperimentEnabledFail,
    (state): FeatureState => ({
      ...state,
      ['selectIsValidateEmailAddressExperimentEnabled']: {
        loaded: true,
        enabled: false,
      },
    }),
  ),
  on(
    fromExperiment.loadIsValidateEmailAddressExperimentEnabledSuccess,
    (state, { enabled }): FeatureState => ({
      ...state,
      ['selectIsValidateEmailAddressExperimentEnabled']: {
        loaded: true,
        enabled,
      },
    }),
  ),

  // RetrievePasswordViaKeycloakExperiment
  on(
    fromExperiment.loadIsRetrievePasswordViaKeycloakExperimentEnabled,
    (state): FeatureState => ({
      ...state,
      ['selectIsRetrievePasswordViaKeycloakExperimentEnabled']: {
        loaded: false,
        enabled: false,
      },
    }),
  ),
  on(
    fromExperiment.loadIsRetrievePasswordViaKeycloakExperimentEnabledFail,
    (state): FeatureState => ({
      ...state,
      ['selectIsRetrievePasswordViaKeycloakExperimentEnabled']: {
        loaded: true,
        enabled: false,
      },
    }),
  ),
  on(
    fromExperiment.loadIsRetrievePasswordViaKeycloakExperimentEnabledSuccess,
    (state, { enabled }): FeatureState => ({
      ...state,
      ['selectIsRetrievePasswordViaKeycloakExperimentEnabled']: {
        loaded: true,
        enabled,
      },
    }),
  ),

  // logout
  on(fromAuthentication.logout, (): FeatureState => initialState),
);

import { InjectionToken } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { NowOverlayComponent } from './component/now-overlay.component';

/**
 * Config Interface for Config
 */
export interface NowOverlayConfig {
  /**
   * auto close Overlay on NavigationEnd and NavigationError
   */
  autoClose: boolean;

  /**
   * Inject your own Angular Component
   */
  overlayComponent?: ComponentType<any>;
}

/**
 * Interface for Config Token
 */
export interface NowOverlayToken {
  /**
   * default config
   */
  default: NowOverlayConfig;

  /**
   * Config for overwriting global config
   */
  config: Partial<NowOverlayConfig>;
}

/**
 * Default config for Overlay
 */
export const DefaultNowOverlayConfig: NowOverlayConfig = {
  /**
   * Closes the Overlay on NavigationEnd or NavigationError
   */
  autoClose: true,

  /**
   * Inject your own Angular Overlay Component
   */
  overlayComponent: NowOverlayComponent,
};

/**
 * InjectionToken for NowOverlay Config Token
 */
export const NOW_OVERLAY_CONFIG = new InjectionToken<NowOverlayToken>(
  'NowOverlayConfig',
);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@nowffc-environment/environment';
import {
  BrazeServiceOptions,
  OptedInChangeEventArgs,
  BrazeService as BrazeSdkService,
} from '@plus/ngx-braze-sdk';
import { catchError, map, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class BrazeService {
  private isLoggedIn = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly brazeService: BrazeSdkService,
    private readonly toastrService: ToastrService,
  ) {}

  login(accountPersonalizationId: string) {
    const brazeOptions: BrazeServiceOptions = {
      apiKey: environment.braze.apiKey,
      baseUrl: environment.braze.baseUrl,
      optedInFn: this.setOptedIn,
      isAuthenticatedInFn: this.isAuthenticated,
    };
    this.isLoggedIn = true;
    this.brazeService.initBrazeServices(brazeOptions).then(() => {
      this.brazeService.login(accountPersonalizationId);
    });
  }
  private readonly isAuthenticated = (): boolean => {
    return this.isLoggedIn;
  };

  private readonly setOptedIn = (config: OptedInChangeEventArgs) => {
    const url = environment.oidc.stsServer + '/api/accounts/opt-ins';

    this.httpClient
      .patch(url, config)
      .pipe(
        take(1),
        map(() => true),
        catchError(() => {
          return of(false);
        }),
      )
      .subscribe((success) => {
        if (success) {
          this.toastrService.success('Änderungen wurden gespeichert');
        } else {
          this.toastrService.error(
            'Änderungen konnten nicht gespeichert werden',
          );
        }
      });
  };

  logout() {
    this.isLoggedIn = false;
    this.brazeService.logout();
  }
}

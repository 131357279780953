<div class="now-ffc-overlay">
  <h1
    class="now-ffc-overlay__headline"
    *ngIf="headline"
    [attr.aria-label]="headline | translate"
  >
    {{ headline | translate }}
  </h1>
  <p
    class="now-ffc-overlay__text"
    *ngIf="text"
    [attr.aria-label]="text | translate"
  >
    {{ text | translate }}
  </p>

  <now-loading-spinner></now-loading-spinner>
</div>

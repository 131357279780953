export type MaybeValue<TValue, TError> =
  | MaybeValueSuccess<TValue>
  | MaybeValueError<TError>;

export interface MaybeValueSuccess<TValue> {
  success: true;
  value: TValue;
}

export interface MaybeValueError<TError> {
  success: false;
  error: TError;
}

export const isError = <TValue, TError>(
  maybeValue: MaybeValue<TValue, TError>,
): maybeValue is MaybeValueError<TError> => !maybeValue.success;

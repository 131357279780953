<button
  class="toast-close-button"
  *ngIf="options.closeButton"
  (click)="remove()"
  aria-label="Close"
>
  <span aria-hidden="true">{{ '&times;' }}</span>
</button>
<div
  *ngIf="title"
  [class]="options.titleClass"
  [attr.aria-label]="title | translate"
>
  {{ title | translate }}
  <ng-container *ngIf="duplicatesCount"
    >[{{ duplicatesCount + 1 }}]</ng-container
  >
</div>
<div
  *ngIf="message && options.enableHtml"
  [class]="options.messageClass"
  [innerHTML]="message | translate"
  role="alertdialog"
  aria-live="polite"
></div>
<div
  *ngIf="message && !options.enableHtml"
  [class]="options.messageClass"
  [attr.aria-label]="message | translate"
  role="alertdialog"
  aria-live="polite"
>
  {{ message | translate }}
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>

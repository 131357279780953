import { CanActivateFn, Router } from '@angular/router';
import { UnleashService } from '@nowffc-shared/services/feature-toggle/unleash.service';
import { inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FeatureToggleGuardConfig {
  allEnabled?: string[];
  allDisabled?: string[];
  redirectUrl: string;
}

export function featureToggleGuard(
  config: FeatureToggleGuardConfig,
): CanActivateFn {
  return () => {
    const unleashService = inject(UnleashService);
    const router = inject(Router);

    const redirect = router.parseUrl(config.redirectUrl);

    const allEnabledNames = config.allEnabled ?? [];
    const allDisabledNames = config.allDisabled ?? [];

    const allEnabled = unleashService.allEnabled(allEnabledNames);
    const allDisabled = unleashService.allDisabled(allDisabledNames);

    return combineLatest([allEnabled, allDisabled]).pipe(
      map(([enabled, disabled]) => (enabled && disabled ? true : redirect)),
    );
  };
}

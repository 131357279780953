import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { effects, reducers } from './';

@NgModule({
  imports: [
    StoreModule.forFeature('experiments', reducers),
    EffectsModule.forFeature(effects),
  ],
})
export class ExperimentsStoreModule {}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { SubscriberDataSignalsState } from '../signals/states/subscriber-data.signals-state';

/**
 * class IsCustomerActiveGuard
 */
@Injectable({
  providedIn: 'root',
})
export class IsCustomerActiveGuard {
  /**
   * Wire DI
   */
  constructor(
    private readonly subscriberDataSignalState: SubscriberDataSignalsState,
    private readonly router: Router,
  ) {}

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated
   */
  async canActivate(): Promise<boolean | UrlTree> {
    try {
      const subscriberData =
        await this.subscriberDataSignalState.getSubscriberData();

      if (subscriberData().internalSubscription.customerStatus === 'LOCKED') {
        return this.router.parseUrl('/sperre');
      }
      if (subscriberData().internalSubscription.status === 'WRITTEN_OFF') {
        return this.router.parseUrl('/konto-hinweis');
      }
    } catch {}

    return true;
  }

  /**
   * Interface that a class can implement to be a guard deciding if a child route can be activated
   */
  async canActivateChild(): Promise<boolean | UrlTree> {
    return this.canActivate();
  }
}

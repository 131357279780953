import { DBConfig } from 'ngx-indexed-db';

export const indexedDbConfig: DBConfig = {
  name: 'ngStorage',
  version: 1,
  objectStoresMeta: [
    {
      store: 'tvnowStorageService',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'key', keypath: 'key', options: { unique: true } },
        { name: 'value', keypath: 'value', options: { unique: false } },
      ],
    },
  ],
};

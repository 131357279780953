import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserService } from '@nowffc-shared/services/user/user.service';

import * as fromAuthentication from '../selectors/authentication.selectors';
import * as userAction from '../actions/user.action';
import { catchError, exhaustMap, filter, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { RedirectService } from '@nowffc-redirect/services/redirect.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ValidateEmailService } from '../../../modules/validate-email/services/validate-email.service';
import { AuthService } from '@nowffc-auth/auth.service';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userAction.loadUser),
      mergeMap(() =>
        this.store.select(fromAuthentication.selectAuthenticationInitialized),
      ),
      filter((initialized) => initialized),
      mergeMap(() => this.authService.isAuthenticated()),
      exhaustMap((isLoggedIn) => {
        if (!isLoggedIn) {
          return of(
            userAction.loadUserFail({
              error: { message: 'user.not.logged.in' },
            }),
          );
        }

        return this.userService.load().pipe(
          map((user) => userAction.loadUserSuccess({ user })),
          catchError((error) => {
            this.toastrService.error(error.message);

            return of(userAction.loadUserFail({ error }));
          }),
        );
      }),
    );
  });

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userAction.updateUser),
      mergeMap(({ user, redirectTarget }) =>
        this.userService.update(user).pipe(
          map((updatedUser) => {
            if (redirectTarget?.startsWith('https')) {
              this.redirectService.go(redirectTarget);
            } else {
              this.router.navigate([redirectTarget ?? '/account']);
            }

            return userAction.updateUserSuccess({ user: updatedUser });
          }),
          catchError((error) => {
            this.toastrService.error(error.message);

            return of(userAction.updateUserFail({ error }));
          }),
        ),
      ),
    );
  });

  resendValidationEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userAction.resendValidationEmail),
      exhaustMap(() =>
        this.validateEmailService.resendVerificationMail().pipe(
          map((success) => {
            if (!success) {
              this.toastrService.error(
                'register.validation.info.email.sent.failed',
              );
              return userAction.resendValidationEmailFail();
            }

            this.toastrService.success('register.validation.info.email.sent');
            return userAction.resendValidationEmailSuccess();
          }),
        ),
      ),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly userService: UserService,
    private readonly validateEmailService: ValidateEmailService,
    private readonly router: Router,
    private readonly redirectService: RedirectService,
    private readonly toastrService: ToastrService,
    private readonly authService: AuthService,
  ) {}
}

import { Injectable } from '@angular/core';
import { WindowRef } from '@nowffc-shared/services/window/window';
import { LocalStorageService } from '@nowffc-shared/services/storage/local-storage.service';
import { ReplaySubject } from 'rxjs';
import { environment } from '@nowffc-environment/environment';
import { addDays } from 'date-fns';

export interface NavigationData {
  url: string;
  buttonLabel: string;
  showContinueWithFree?: boolean;
}
/**
 * Redirect Service
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private readonly STORAGE_KEY_REDIRECT_URL = 'redirect_url';
  private readonly STORAGE_KEY_ORIGIN_URL = 'origin_url';
  private readonly STORAGE_KEY_NAVIGATION_AFTER_REGISTRAION =
    'navigation_after_registration';

  private readonly originUrlSubject = new ReplaySubject<string>(1);
  originUrl$ = this.originUrlSubject.asObservable();

  /**
   * Wire DI
   */
  constructor(
    private readonly windowRef: WindowRef,
    private readonly storageService: LocalStorageService,
  ) {
    this.originUrlSubject.next(this.getOriginUrl() || environment.wwwUrl);
  }

  /**
   * checks if redirect is valid
   */
  private static isValidRedirectUrl(url: string): boolean {
    const testAElement = document.createElement('a');
    testAElement.href = url.toLowerCase();
    return !(
      testAElement.protocol !== 'https:' ||
      (!testAElement.host.endsWith('.tvnow.de:7000') &&
        !testAElement.host.endsWith('.tvnow.de') &&
        !testAElement.host.endsWith('.aws-cbc.cloud') &&
        !testAElement.host.endsWith('.tvnow.at') &&
        !testAElement.host.endsWith('.tvnow.ch') &&
        !testAElement.host.endsWith('.rtl.de'))
    );
  }

  /**
   * check if is internal url
   */
  private static isRelativeUrl(url: string): boolean {
    return url[0] === '/' && url[1] !== '/';
  }

  /**
   * redirect URL
   */
  setRedirectUrl(url: string): boolean {
    return this.setUrl(url, this.STORAGE_KEY_REDIRECT_URL);
  }

  getRedirectUrl(): string {
    return this.getUrl(this.STORAGE_KEY_REDIRECT_URL);
  }

  hasRedirectUrl(): boolean {
    return this.hasUrl(this.STORAGE_KEY_REDIRECT_URL);
  }

  deleteRedirectUrl(): void {
    this.deleteStorageItem(this.STORAGE_KEY_REDIRECT_URL);
  }

  executeRedirect(): void {
    this.execute(this.STORAGE_KEY_REDIRECT_URL);
  }

  /**
   * Navigation after registration
   */
  setNavigationAfterRegistration(data: NavigationData): void {
    this.storageService.set(
      this.STORAGE_KEY_NAVIGATION_AFTER_REGISTRAION,
      { showContinueWithFree: data.showContinueWithFree ?? false, ...data },
      addDays(new Date(), 1),
    );
  }

  getNavigationAfterRegistration(): NavigationData | null {
    return this.storageService.get(
      this.STORAGE_KEY_NAVIGATION_AFTER_REGISTRAION,
    );
  }

  deleteNavigationAfterRegistration() {
    this.storageService.delete(this.STORAGE_KEY_NAVIGATION_AFTER_REGISTRAION);
  }

  /**
   * origin URL
   */
  setOriginUrl(url: string): boolean {
    const urlSet = this.setUrl(url, this.STORAGE_KEY_ORIGIN_URL);

    if (urlSet) {
      this.originUrlSubject.next(url);
    }

    return urlSet;
  }

  getOriginUrl(): string {
    return this.getUrl(this.STORAGE_KEY_ORIGIN_URL);
  }

  hasOriginUrl(): boolean {
    return this.hasUrl(this.STORAGE_KEY_ORIGIN_URL);
  }

  deleteOriginUrl(): void {
    this.deleteStorageItem(this.STORAGE_KEY_ORIGIN_URL);
  }

  executeReturnToOrigin(): void {
    this.execute(this.STORAGE_KEY_ORIGIN_URL);
  }

  getHomeUrl(): string {
    if (this.hasOriginUrl()) {
      return this.getOriginUrl();
    }

    if (this.hasRedirectUrl()) {
      return this.getRedirectUrl();
    }

    return environment.wwwUrl;
  }

  returnToHome(): void {
    this.go(this.getHomeUrl());
  }

  /**
   * go to url
   */
  go(redirectTarget: string): void {
    // leaving ffc, resetting storage
    this.resetStorage();

    this.windowRef.nativeWindow.location.href = redirectTarget;
  }

  private resetStorage() {
    this.deleteStorageItem(this.STORAGE_KEY_ORIGIN_URL);
    this.deleteStorageItem(this.STORAGE_KEY_REDIRECT_URL);
  }

  private setUrl(url: string, storageKeyName: string): boolean {
    if (
      !RedirectService.isRelativeUrl(url) &&
      !RedirectService.isValidRedirectUrl(url)
    ) {
      return false;
    }

    this.storageService.set(storageKeyName, url, addDays(new Date(), 1));

    return true;
  }

  private getUrl(storageKey: string): string {
    const returnUrl = this.storageService.get<string>(storageKey);

    if (
      returnUrl &&
      (RedirectService.isRelativeUrl(returnUrl) ||
        RedirectService.isValidRedirectUrl(returnUrl))
    ) {
      return returnUrl;
    } else {
      return '';
    }
  }

  private hasUrl(storageKey: string): boolean {
    return this.storageService.has(storageKey);
  }

  private deleteStorageItem(storageKey: string): void {
    this.storageService.delete(storageKey);
  }

  private execute(storageKey: string): void {
    const url = this.getUrl(storageKey);

    this.go(url);
  }
}

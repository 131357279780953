/* istanbul ignore file */
import { HandledException } from '@nowffc-shared/exceptions/handledException';

/**
 * ToastrException class
 */
export class ToastrException extends HandledException {
  /**
   * Constructor
   */
  constructor(m: string, headline = 'Fehler', reportToTracker = false) {
    super(m);
    this.name = headline;
    this.reportToTracker = reportToTracker;
    this.showInToastr = true;
  }
}
